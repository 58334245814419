
import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import { useParams } from 'react-router-dom';
import UserForm from "../userForm";
import styled from "styled-components";

export default function ScreeningDetail(){

  const { getAPI } = useAPI()
  const { user } = useAuth()
  let { user_idx } = useParams();
  const [apiData, setApiData] = useState([])

  const url = `/admin/new/detail?user_idx=${user_idx}`
  
  useEffect(() => {
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, url])

  return(
    <Div>
      <div className="hearder" style={{ color: "#000" }}>회원가입 승인</div>
      {apiData.length !== 0 && <UserForm userData={apiData} user_idx={user_idx} readOnly={true} isScreening={true}/>}
    </Div>
  )
}

const Div = styled.div`
  max-width: 780px;
  margin: 0 auto;
  .hearder{
    margin-top: 80px;
    margin-bottom: 20px;
    font-size: 2rem;
  }
`