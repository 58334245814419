
import useHeader from "./useHeader"
import { useLocation, useNavigate } from "react-router-dom"

export default function useAPI(){
  const { headers } = useHeader()
  const location = useLocation()
  const navigate = useNavigate()

  const getAPI = async (url, user) => {
    return await fetch("/api"+url, {
      method: "GET",
      credentials: 'same-origin',
      headers: await headers(user),
    }).then(async (res) => {
      return {status:res.status, body: await res.json()}
    })
    .then(res => {
      if (res.status === 200) {
        return { data:res.body.data, result:res.body.result }
      } 
      else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
          
        return { responseCode,responseMessage, result }
      }
    })
  }



  const postAPI = async (url, user, data) => {
    return await fetch("/api"+url, {
      method: "POST",
      credentials: 'same-origin',
      headers: await headers(user),
      body: JSON.stringify(data)
    })
    .then(async (res) => {
      return { status: res.status, body: await res.json() }
    }
    )
    .then(res => {
      if (res.status === 200) {
        if(url === '/jwt/login'){
          return { data:res.body, result: true }
        } else {
          return { data:res.body.data, result:res.body.result }
        }
      }
      else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }

  const putAPI = async (url, user, data) => {
    return await fetch("/api"+url, {
      method: "PUT",
      credentials: 'same-origin',
      headers: await headers(user),
      body: JSON.stringify(data)
    })
    .then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
    .then(res => {
      if (res.status === 200) {
        return { data:res.body.data, result:res.body.result }
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }


  const deleteAPI = async (url, user) => {
    return  await fetch("/api"+url, {
      method: "DELETE",
      credentials: 'same-origin',
      headers: await headers(user),
    }).then(async (res) => {
      return {status:res.status, body: await res.json()}
    })
    .then(res => {
      if (res.status === 200) {
        return { result:res.body.result }
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }

  const uploadImageAPI = async (img) => {
    if(typeof(img) === "string"){
      return {result:"success", data:{img_url:img}}
      // paremeter가 img url인 경우 api 호출 없이 return
    }
    const formData = new FormData();
    formData.append("img", img);
    return await fetch(`/api/admin/upload-img`, {
      method: "PUT",
      body: formData,
    }).then(async (res) => {
      return {status:res.status, body: await res.json()}
    })
    .then(res => {
      if (res.status === 200) {
        return { data:res.body.data, result:res.body.result }
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }
  const uploadSeveralImageAPI = async (imgs) => {
    let fileObjects = []
    let imageUrls = []
    for(let i = 0;i<imgs.length;i++){
      if(typeof(imgs[i]) === "object"){
        fileObjects.push(imgs[i])
        imageUrls.push(0)
      } else {
        imageUrls.push(imgs[i])
      }
    }
    if(fileObjects.length === 0){
      return {result:"success",data:{imageUrls}}
      // paremeter array의 item이 모두 img url인 경우 api 호출 없이 return
    } else {
      const formData = new FormData();
      fileObjects.map((imgs) => {
        return formData.append(`imgs`, imgs);
      });

      return await fetch(`/api/admin/upload-imgs`, {
        method: "PUT",
        body: formData,
      }).then(async (res) => {
        return {status:res.status, body: await res.json()}
      })
      .then(res => {
        if (res.status === 200) {
          let { img_url_list } = res.body.data
          let img_url_list_index = 0;
          for(let i = 0; i<imageUrls.length;i++){
            if(imageUrls[i] === 0){
              imageUrls[i] = img_url_list[img_url_list_index]
              img_url_list_index = img_url_list_index+1
            }
          }
          return { data:{imageUrls}, result:res.body.result }
        } else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false
          navigate(location.pathname, {
            state:{errorStatusCode: responseCode}
          });
          return { responseCode,responseMessage, result }
        }
      })
    }
  }

  // const uploadSeveralImageAPI = async (imgs) => {
  //   let fileObjects = []
  //   let imageUrls = []
  //   for(let i = 0;i<imgs.length;i++){
  //     if(typeof(imgs[i]) === "object"){
  //       fileObjects.push(imgs[i])
  //       imageUrls.push(0)
  //     } else {
  //       imageUrls.push(imgs[i])
  //     }
  //   }
  //   if(fileObjects.length === 0){
  //     return {result:"success",data:{imageUrls}}
  //     // paremeter array의 item이 모두 img url인 경우 api 호출 없이 return
  //   } else {
  
  //     const formData = new FormData();
  //     fileObjects.map((imgs) => {
  //       return formData.append(`imgs`, imgs);
  //     });
  //     let response = await fetch(`/function/upload-imgs`, {
  //       method: "PUT",
  //       body: formData,
  //     }).then((res) =>res.json());
  
  //     let { img_url_list } = response.data
  //     let img_url_list_index = 0;
  //     for(let i = 0; i<imageUrls.length;i++){
  //       if(imageUrls[i] === 0){
  //         imageUrls[i] = img_url_list[img_url_list_index]
  //         img_url_list_index = img_url_list_index+1
  //       }
  //     }
  //     return {result:"success",data:{imageUrls}}
  
  //   }
  // }

  return { getAPI, postAPI, putAPI, deleteAPI, uploadImageAPI, uploadSeveralImageAPI}

}
