import styled from "styled-components";
import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import PageNumber from "../../../components/pageNumber"
import maleFace from "../../../images/maleFace.svg"
import Logo from "../../../images/opacityLogo.png"
import { GMTtoKSTconverter } from "../../../converter";
import BlockUI from "../../../components/blockUI/blockUI";
import { useScrollToTop } from "../../../customHook/userScrollToTop";
import { useModal } from "../../../components/modal/modal";
import Button from "../../../components/button";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


export default function Block() {

  const navigate = useNavigate()
  const [userType, setUserType] = useState(1) //  아티스트 1, 광고주 2
  const [searchParams] = useSearchParams();
  let type = searchParams.get('type')
  const { getAPI } = useAPI()
  const { user } = useAuth()
  const [selectPageno, setSelectPageno] = useState(1)
  const [apiData, setApiData] = useState([])
  const [total_pageno, setTotal_pageno] = useState(1)
  const [blocking, setBlocking] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  
  const url = `/admin/block?pageno=${selectPageno}&u_type=${userType}`
  const { scrollToTop } = useScrollToTop()
  const { reportAndBlockModal } = useModal()

  useEffect(() => {
    scrollToTop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (type === 'client') {
      setUserType(2)
    } else {
      setUserType(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    setBlocking(true)
    const fetchAPI = async () => {
      if(!searchKeyword){
        let { data } = await getAPI(url, user)
        setApiData(data.user)
        setTotal_pageno(data.total_pageno)
        setBlocking(false)
      } else {
        let newUrl = url+`&search=${searchKeyword}`
        let { data } = await getAPI(newUrl, user)
        setApiData(data.user)
        setTotal_pageno(data.total_pageno)
        setBlocking(false)
      }
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno, user, userType, url, searchKeyword])

  return (
    <Div>
      <div className="header">정지 관리</div>
      <input className="search" type="text" placeholder="이름 또는 전화번호를 검색해주세요" value={searchKeyword} onChange={(e) => { setSearchKeyword(e.target.value) }} />
      <div className="seletType">
        <div style={(userType === 1) ? { color: 'black' } : { color: '#7F7F7F' }} onClick={() => {setSelectPageno(1);navigate('/block?type=artist') }}>
          아티스트
        </div>
        <div style={(userType === 2) ? { color: 'black' } : { color: '#7F7F7F' }} onClick={() => {setSelectPageno(1);navigate('/block?type=client') }}>
          광고주
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>로고/프로필</th>
            <th>계정</th>
            {userType === 1 ?
              <th>직무</th>
              :
              <th>분야</th>
            }
            <th>인증 이름</th>
            <th>연락처</th>
            <th>회원가입일시</th>
            <th>마지막 로그인</th>
            <th>사유보기</th>
          </tr>
        </thead>
        <tbody>
          {apiData?.map((user) => {
            let user_image = user.user_profile || (userType === 1 ? maleFace : Logo)

            let lastDate = GMTtoKSTconverter(user.last_datetime)
            let blockDate = GMTtoKSTconverter(user.block_datetime)
            
            return (
              <tr key={user.user_idx}>
                <td>{user.idx}</td>
                <td><img src={user_image} className={`${userType === 1 ? "artist" : "client"}`} alt="profile" /></td>
                <td>              
                  <Link to={`/organization/${user.user_idx}`}>
                    {user.user_id}
                  </Link>
                </td>
                <td>{user.job_detail}</td>
                <td>{user.user_name}</td>
                <td>{user.user_phone}</td>
                <td>{`${lastDate.getFullYear()}.${lastDate.getMonth() + 1}.${lastDate.getDate()}`}</td>
                <td>{`${blockDate.getFullYear()}.${blockDate.getMonth() + 1}.${blockDate.getDate()} ${blockDate.getHours()}:${blockDate.getMinutes()}`}</td>
                <td><Button style={{ height:"40px", lineHeight:"40px", width:"80px"}} onClick={()=>{reportAndBlockModal(user.user_block_detail)}}>보기</Button></td>
              </tr>
            )
          })
          }
        </tbody>
      </table>
      <BlockUI blocking={blocking} />
      {total_pageno >= 2 && <PageNumber total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  .header{
    font-size:1.5rem;
    padding:15px;
  }
  .search{
    font-size:1.25rem;
    padding:10px;
    width:100%;
    box-sizing: border-box;
    border-top:1px solid #D9D9D9;
    border-bottom:1px solid #D9D9D9;
    border-left:0;
    border-right:0;
  }
  .seletType{
    display: flex;
    height:50px;
    line-height:50px;
    text-align:center;
    >div{
      padding:0 40px;
      cursor:pointer;
    }
  }

  table{
    border-collapse: collapse;
    width: 98%;

    thead{
      *{font-family:'AppleSDGothic-Bold';}
    }
    tr{
      height:60px;
      line-height: 60px;
      color: #222222;
      td{
        padding:0 10px;
        text-align:center;
        img{
          object-fit: cover;
        }
        .artist{
          width:80px;
          height:80px;
        }
        .client{
          max-width:150px;
          max-height:80px;
          width:100%;
          min-width: 120px;
          object-fit: contain;
        }
        .accept{
          text-align:center;
          padding:10px 0;
        }
      }
    }

  }
`