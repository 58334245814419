import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import { useParams } from 'react-router-dom';
import UserForm from "../userForm";
import maleFace from "../../../images/maleFace.svg"
import Logo from "../../../images/opacityLogo.png"
import styled from "styled-components";

export default function OrganizationDetail() {

  const { getAPI } = useAPI()
  const { user } = useAuth()
  let { user_idx } = useParams();
  const [apiData, setApiData] = useState([])
  const [random, setRandom] = useState(Math.random())

  const url = `/admin/old/detail?user_idx=${user_idx}`

  const reload = () => {
    setRandom(Math.random())
  }

  useEffect(() => {
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, url, random])

  return (
    <Div>
      <div className="hearder" style={{ color: "#000" }}>회원정보 수정</div>
      {apiData &&
        <div className="profile">
          <img src={apiData.user_profile || (apiData.user_privilege === 1 ? maleFace : Logo)} className={`${apiData.user_privilege === 1 ? "artist" : "client"}`} alt="profile" />
        </div>
      }
      {apiData.length !== 0 && <UserForm userData={apiData} user_idx={user_idx} reload={reload}/>}
    </Div>
  )
}

const Div = styled.div`
  max-width: 780px;
  margin: 0 auto;
  .hearder{
    margin-top: 80px;
    margin-bottom: 20px;
    font-size: 2rem;
    }
  .profile{
    width:90%;
    margin: 0 auto;
    img{
      object-fit: cover;
      }
    .artist{
      width:150px;
      height:150px;
    }
    .client{
      max-width:150px;
      max-height:80px;
      width:100%;
      min-width: 120px;
      object-fit: contain;
    }
  }
`