import styled from "styled-components"

export default function Button(props){
  return(
    <Div {...props}/>
  )
}

const Div = styled.button`
  background-color: #222222;
  max-width:380px;
  width:100%;
  height:60px;
  line-height: 60px;
  cursor: pointer;
  color:white;
  display: flex;
  justify-content:center ;
  text-align: center;
  border-radius: 0;
  border:none;
  margin:0 auto;
  padding:0px;
  *{
    font-family: 'Jost';
  }
  @media (max-width: 720px) {
    max-width:360px;
    width:90%;
  }
`