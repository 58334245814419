import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { questionCategory } from "../../../keywords/keywords";
import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import PageNumber from "../../../components/pageNumber"
import { GMTtoKSTconverter } from "../../../converter";
import BlockUI from "../../../components/blockUI/blockUI";
import { useScrollToTop } from "../../../customHook/userScrollToTop";
import { Link } from "react-router-dom";

export default function Question() {

  const navigate = useNavigate()

  const { getAPI } = useAPI()
  const { user } = useAuth()
  const [selectPageno, setSelectPageno] = useState(1)
  const [apiData, setApiData] = useState([])
  const [total_pageno, setTotal_pageno] = useState(1)
  const [blocking, setBlocking] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const url = `/admin/question?pageno=${selectPageno}`
  const { scrollToTop } = useScrollToTop()

  useEffect(() => {
    scrollToTop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);


  useEffect(() => {
    setBlocking(true)
    const fetchAPI = async () => {
      if(!searchKeyword){
        let { data } = await getAPI(url, user)
        setApiData(data.question)
        setTotal_pageno(data.total_pageno)
        setBlocking(false)
      } else {
        let newUrl = url+`&search=${searchKeyword}`
        let { data } = await getAPI(newUrl, user)
        setApiData(data.question)
        setTotal_pageno(data.total_pageno)
        setBlocking(false)
      }
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno, user, url, searchKeyword])

  return (
    <Div>
      <div className="header">문의 관리</div>
      <input className="search" type="text" placeholder="이름, 문의 제목 또는 문의 내용을 검색해주세요" value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>타입</th>
            <th>계정</th>
            <th>이름</th>
            <th>문의유형</th>
            <th>제목</th>
            <th>등록일시</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {apiData?.map((question) => {
            let askTime = GMTtoKSTconverter(question.question_datetime)
            return (
              <tr key={question.idx}>
                <td>{question.idx}</td>
                <td>{question.user_privilege === 1 ? "아티스트":"광고주"}</td>
                <td>
                  <Link to={`/organization/${question.user_idx}`}>
                    {question.user_id}
                  </Link>
                </td>
                <td>{question.user_name}</td>
                <td>{questionCategory[question.question_category]}</td>
                <td>{question.question_title}</td>
                <td>{`${askTime.getFullYear()}.${askTime.getMonth() + 1}.${askTime.getDate()} ${askTime.getHours()}:${askTime.getMinutes()}`}</td>
                <td>{question.question_type ? '답변 완료' : '답변 대기중'}</td>
                <td>
                <div className="buttons">
                  {!question.question_type ? 
                  <div className="reply" onClick={() => {navigate(`/question/answer`,{state:{ question } })}}>답변하기</div>
                  :
                  <div className="edit" onClick={() => {navigate(`/question/answer`, { state: { question } })}}>보기</div>
                }
                </div>
                </td>
              </tr>
            )
          })
          }
        </tbody>
      </table>
      <BlockUI blocking={blocking} />
      {total_pageno >= 2 && <PageNumber total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  .header{
    font-size:1.5rem;
    padding:15px;
  }
  .search{
    font-size:1.25rem;
    padding:10px;
    width:100%;
    box-sizing: border-box;
    border-top:1px solid #D9D9D9;
    border-bottom:1px solid #D9D9D9;
    border-left:0;
    border-right:0;
  }
  table{
    border-collapse: collapse;
    width: 100%;
    margin:40px 0 80px;

    thead{
      *{
        font-family:'AppleSDGothic-Bold';
        font-size:1.25rem;
      }
      th{
      padding:10px;
    }
    }
    tr{
      color: #222222;
      td{
        border-bottom:1px solid #D9D9D9;
        text-align:center;
        height:100px;
      }
    }
    .buttons{
      display: flex;
      justify-content: center;
      >div{
        width:95px;
        height:40px;
        line-height: 40px;
        margin-left:10px;
        cursor: pointer;
      }
      .edit{
        border: 1px solid #7F7F7F;
        box-sizing: border-box;
      }
      .reply{
        background-color: #222;
        color: #FFFFFF;
      }
    }

  }
`