import { useState, useEffect } from "react"
import styled from "styled-components"
import Button from "../../components/button"
import { useNavigate } from "react-router-dom"
import useAPI from '../../api/useAPI';
import { useModal } from "../../components/modal/modal";

export default function FindID() {

  const [isVerrfied, setVerrified] = useState(false)
  const [user_id, setUser_id] = useState('')
  const { getAPI, postAPI } = useAPI()
  const navigate = useNavigate()
  const { modalShow, modalClose } = useModal()

  const domain_name =  window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    // 해당 component mount시 실행
    async function receiveMessage(e) {
      if(e.origin !== domain_name || e.data.path !== '/nice-callback') return
      // a.value = e.data.check; // 본인인증 완료시 완료됬다는 check 넘길 수 있음
      if(e.data.check){
        let url = `/jwt/find-id`
        let body = {"token_version_id": e.data.token}
        let { data, result} = await postAPI(url, false, body)
        // error시 40105

        if(result === 'success'){
          setUser_id(data.user_id)
          setVerrified(true)
        } else {
          modalShow({ message: "본인인증에 실패하였습니다", actionButton: <div className="button" onClick={(e) => {modalClose();onClickCertify(e)}}>다시 시도하기</div> })
        }
      } else {
        modalShow({ message: "본인인증에 실패하였습니다", actionButton: <div className="button" onClick={(e) => {modalClose();onClickCertify(e)}}>다시 시도하기</div> })
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickCertify = async (e) => {
    e.preventDefault();
    let url = `/user/nice?domain_name=${domain_name}`
    const { data } = await getAPI(url)
    const { token_version_id, enc_data, integrity_value } = data
    window.open('', 'popup', "width=600,height=400,left=200,top=200");
    document.niceForm.target = "popup";
    document.niceForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
    document.niceForm.token_version_id.value = token_version_id;
    document.niceForm.enc_data.value = enc_data;
    document.niceForm.integrity_value.value = integrity_value;
    document.niceForm.submit();
  }

  return (
    <Div>
      {!isVerrfied ?
        <>
          <div className="content">
            휴대폰 본인 인증을 통해<br />아이디 (이메일)을 확인합니다
          </div>
          <form name="niceForm" id="niceForm" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
            <input type="hidden" id="m" name="m" value="service" />
            <input type="hidden" id="token_version_id" name="token_version_id" value="" />
            <input type="hidden" id="enc_data" name="enc_data" />
            <input type="hidden" id="integrity_value" name="integrity_value" />
          </form>
          <Button style={{ margin: '0px auto', backgroundColor: '#7F7F7F' }} onClick={(e) => {onClickCertify(e)}}>휴대폰 인증하기</Button>
        </>
        :
        <>
          <div className="content">
            <div style={{ margin: '20px' }}>가입 이메일</div>
            <div style={{ fontSize: "1.25rem" }}>{user_id}</div>
          </div>
          <Button style={{ margin: '0px auto' }} onClick={() => { navigate('/start') }}>로그인 페이지로</Button>
        </>
      }
    </Div>
  )
}

const Div = styled.div`
  text-align:center;
  .content{
    display: flex;
    flex-direction: column;
    height: 330px;
    justify-content: center;
    *{font-size: 1.125rem}
    text-align:center;

  }
`