
import styled from 'styled-components';
import { useState } from 'react';
import { useWindowSize } from '../../customHook/useWindowSize';
import { useModal } from './modal';
import Button from '../button';

export default function ReportAndBlockModal({ data, user_idx, callback }) {
  // 신고사유 : readOnly
  // 정지사유 : read, write(user_idx 있는 경우)


  const { modalShow, modalClose } = useModal()
  const { innerWidth } = useWindowSize()

  const labelTexts = ['거짓 정보 게시', '저작물 외 음란물 게시', '검증되지 않은 이용자', '기타']
  const [checked, setChecked] = useState([false, false, false, false])
  const reason = JSON.parse(data)
  const [taxtValue, setTextValue] = useState(data ? () => {
    for (let i = 0; i < reason.length; i++) {
      if (labelTexts.indexOf(reason[i]) === -1) {
        return reason[i]
      }
    }
  }
    :
  ''
  )  
  const [errors, setErrors] = useState('')

  let handleOnChange = (index) => {
    const updatedCheckedState = checked.map((checked, i) => i === index ? !checked : checked)
    setChecked(updatedCheckedState);
  }

  let onBlock = () => {
    let reason = []
    const reasonCheck = checked.filter((checked, index) => checked && reason.push(labelTexts[index]));
    if (reasonCheck.length === 0) {
      setErrors('정지 사유를 선택해주세요.')
      return
    }

    if (checked[checked.length - 1]) {
      if (taxtValue === "") {
        setErrors('기타 내용을 입력해주세요.')
        return
      }
      reason.pop()
      reason.push(taxtValue)
    }
    setErrors('')
    modalShow({ message: "해당 계정을 정지하시겠습니까?", actionButton: <div className="button" onClick={() => callback(user_idx, reason)}>정지하기</div> })
  }

  return (
    <Div innerWidth={innerWidth}>
      {data ? // read
        <div>
          <div className="hearder" style={{ color: "#000" }}>신고/정지 사유</div>
          <div className="checkbox round">
            {labelTexts.map((value) => (
              <p key={value}>
                <input
                  id={value}
                  type="checkbox"
                  checked={
                    value !== '기타' ? JSON.parse(data).indexOf(value) !== -1 : taxtValue ? true : false
                  }
                  readOnly={true}
                />
                <label htmlFor={value}>{value}</label>
              </p>
            ))}
          </div>
          <textarea value={taxtValue} readOnly={true} />
          <Button style={{ margin: "20px auto 10px" }} onClick={() => { modalClose() }}>닫기</Button>
        </div>
        :
        <div>
          <div className="hearder" style={{ color: "#000" }}>정지 사유</div>
          <div className="checkbox round">
            {labelTexts.map((value, index) => (
              <p key={value}>
                <input
                  id={value}
                  type="checkbox"
                  onChange={() => handleOnChange(index)}
                />
                <label htmlFor={value}>{value}</label>
              </p>
            ))}
          </div>
          <textarea placeholder="내용을 입력해주세요." value={taxtValue} onChange={(e) => { setTextValue(e.target.value) }} />
          {errors && <span className="errorMesage">{errors}</span>}
          <Button style={{ margin: "20px auto 10px" }} onClick={() => { onBlock() }}>정지하기</Button>
        </div>
      }

    </Div>
  )
}

const Div = styled.div`
  max-width:380px;
  width: calc(${props => props.innerWidth}px * 0.8);
  margin: 0 auto 40px;
  background-color: white;
  & > div{
    width: 90%;
    margin:0 auto;
    height: 480px;
    .hearder{
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 1.5rem;
    }
    & > span{
      color:#EB5757;
    }
    textarea{
      width: 100%;
      height: 108px;
      border: 1px solid #ECECEC;
      resize: none;
      padding:10px;
      box-sizing: border-box;
      padding-bottom:32px;
    }
    textarea::placeholder{
      font-size: 0.875rem;
      color: #777777;
      font-family: 'AppleSDGothicNeoR00';
    }
  }
  .errorMesage{
    color:#EB5757;
  }
`