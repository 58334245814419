import { createContext, useContext, useMemo, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopContext = createContext();

export const ScrollToTopProvider = ({ children }) => {

  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const scrollToTop = useCallback(() => {
    document.documentElement.scrollTo({top: 0,left: 0,behavior: "instant"});
  }, [])

  const value = useMemo(
    () => ({ scrollToTop }),
    /*eslint-disable */
    []
    /*eslint-enable */
  );

  return (
    <ScrollToTopContext.Provider value={value}>
      {children}
    </ScrollToTopContext.Provider>
  )
};
export const useScrollToTop = () => {
  return useContext(ScrollToTopContext);
};
