import MainLogo from "../../images/mainLogo.svg"
import styled from "styled-components"
import { useModal } from "./modal"

// 아이디 중복 확인 모달

export default  function ConfirmModal({message, callback, close}){

  const { modalClose } = useModal()
  return(
    <Div>
      <img className="modalLogo" src={MainLogo} alt="Logo"/>
      <div className="message">{message}</div>
      <div className="flexContainer">
        <div onClick={()=>{callback();close ? close() : modalClose()}}>예</div>
        <div onClick={()=>{close ? close() : modalClose()}}>아니요</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  align-items: center;

  .modalLogo {
    width: 109px;
    height: 31px;
    position: absolute;
    top: 81px;
  }

  .message {
    position: absolute;
    top: 144px;
  }

  .flexContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 15px;

    div {
      width: 46%;
      height: 60px;
      line-height: 60px;
      background: #222222;
      color: #FFFFFF;
    }
  }
`
