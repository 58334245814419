import {  useNavigate } from "react-router-dom";

export default function PageNotFound({withApiError}) {
  let navigate = useNavigate();

  return (
    <div>
      <div>
        <div onClick={() =>withApiError ? navigate(-2) : navigate(-1)}>Back</div>
      </div>
      page not found!!
    </div>
  )
}