
import styled from 'styled-components';
import { useState } from 'react';
import { useModal } from './modal';
import { useAuth } from '../../auth/auth';
import useAPI from '../../api/useAPI';
import { useWindowSize } from '../../customHook/useWindowSize';
import Button from '../button';

export default function SendLMS({ data }) {

  const [taxtValue, setTextValue] = useState("")
  const { innerWidth } = useWindowSize()
  const { user } = useAuth()
  const { postAPI } = useAPI()
  const { modalShow, modalClose } = useModal()
  const [errors, setErrors] = useState('')


  let onSubmit = async (idx) => {
    if(taxtValue.length === 0){
      setErrors("메세지를 입력해주세요")
      return
    }
    if(taxtValue.length > 1000){
      setErrors("1000자 이내로 입력해주세요")
      return
    }
    let data = {}
    let user_idx = []

    user_idx.push(idx)
    data.user_idx = user_idx
    data.content = taxtValue

    let { result } = await postAPI('/admin/lms', user, data)
    if (result === 'success') {
      modalShow({ message: `메세지 전송이 완료되었습니다.`, actionButton: <div className="button" onClick={() => modalClose()}>닫기</div> })
    }
  }

  return (
    <Div innerWidth={innerWidth}>
      <div className="hearder" style={{ color: "#000" }}>메세지 전송</div>
      <div className="content">

        <div>
          <label>계정</label>
          <div className="userData">{data.user_id}</div>
        </div>

        <div>
          <label>{data.user_privilege === 1 ? "이름" : "회사명"}</label>
          <div className="userData">{data.user_privilege === 1 ? data.user_name : data.company_name}</div>
        </div>

        <div>
          <label>번호</label>
          <div className="userData">{data.user_phone}</div>
        </div>

        <div>
          <label>{data.user_privilege === 1 ? "직무" : "분야"}</label>
          <div className="userData">{data.job_detail}</div>
        </div>

        <textarea placeholder="메세지 입력해주세요.(1000자 이내)" value={taxtValue} onChange={(e) => { setTextValue(e.target.value) }} />
        {errors && <span className="errorMesage">{errors}</span>}
        <Button style={{ margin: "20px auto 20px", width:"90%" }} onClick={(e) => {e.preventDefault();onSubmit(data.user_idx) }}>전송하기</Button>
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width:380px;
  width: calc(${props => props.innerWidth}px * 0.8);
  margin: 0 auto 40px;
  background-color: white;
  height: 580px;

  .hearder{
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 1.5rem;
    }

  .content > div{
    width: 90%;
    margin:0 auto;
    display: flex;
    justify-content:space-between;
    height:40px;
    line-height:40px;
    *{
      font-size:1.25rem;
    }
  
    & > span{
      color:#EB5757;
    }
    img{
      width:100px;
      height:100px;
    }
    .userData{
      width:50%;
      text-align:left;
    }
   
  }
  textarea{
    width: 90%;
    height: 200px;
    border: 1px solid #222222;
    resize: none;
    padding:10px;
    box-sizing: border-box;
    padding-bottom:32px;
    margin-top:20px;
  }
  textarea::placeholder{
    font-size: 0.875rem;
    color: #777777;
    font-family: 'AppleSDGothicNeoR00';
  }
  .errorMesage{
    color:#EB5757;
  }
`