import { useState, useEffect } from "react"
import styled from "styled-components"
import Button from "../../components/button"
import { useForm } from "react-hook-form";
import useAPI from '../../api/useAPI';
import BlockUI from "../../components/blockUI/blockUI";

import { useNavigate } from "react-router-dom"
import { useModal } from "../../components/modal/modal";

export default function FindPW() {

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const { getAPI, putAPI } = useAPI()

  const { modalShow, modalClose } = useModal()

  const [isVerrfied, setVerrified] = useState(false)
  const navigate = useNavigate()
  const [token_version_id, setToken_version_id] = useState('')
  const [blocking, setBlocking] = useState(false)

  const domain_name =  window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    function receiveMessage(e) {
      if(e.origin !== domain_name || e.data.path !== '/nice-callback') return
      // a.value = e.data.check; // 본인인증 완료시 완료됬다는 check 넘길 수 있음
      if(e.data.check){
        setToken_version_id(e.data.token)
        setVerrified(true)
      } else {
        modalShow({ message: "본인인증에 실패하였습니다", actionButton: <div className="button" onClick={() => {onClickCertify();modalClose();}}>다시 시도하기</div> })
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const findPWSubmit = async (data) => {
    setBlocking(true)
    let url = `/jwt/check-id?user_id=${data.user_id}`
    const { result } = await getAPI(url)
    if (result) {
      setBlocking(false)
      modalShow({ message: '이메일을 다시 확인해주세요.', actionButton: <div className="button" onClick={() => modalClose()}>다시 시도하기</div> })
    } else {
      setBlocking(false)
      onClickCertify();
    }
  }

  const changePWSubmit = async (data) => {
    delete data.user_pw_check;
    data.token_version_id = token_version_id
    setBlocking(true)

    let url = `/jwt/find-passwd`
    const { result } = await putAPI(url, false, data)
    if (result === 'success') {
      setBlocking(false)
      modalShow({ message: '비밀번호가 정상적으로 변경되었습니다.', actionButton: <div className="button" onClick={() => {modalClose();navigate('/start')}}>로그인</div> })
    } else {
      setBlocking(false)
      modalShow({ message: `알 수 없는 에러입니다. 관리자에게 문의해주세요.`, actionButton: <div className="button" onClick={() => modalClose()}>다시 시도하기</div> })
    }
  }

  const onClickCertify = async () => {
    let url = `/user/nice?domain_name=${domain_name}`
    const { data } = await getAPI(url)
    const { token_version_id, enc_data, integrity_value } = data
    window.open('', 'popup', "width=600,height=400,left=200,top=200");
    document.niceForm.target = "popup";
    document.niceForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
    document.niceForm.token_version_id.value = token_version_id;
    document.niceForm.enc_data.value = enc_data;
    document.niceForm.integrity_value.value = integrity_value;
    document.niceForm.submit();
  }

  return (
    <Div>
      {!isVerrfied ?
        <>
          <div className="content">
            가입한 아이디(이메일)를 입력해주세요.<br />
            휴대폰 본인인증을 통해 <br />
            비밀번호를 변경합니다.
            <form name="niceForm" id="niceForm" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
              <input type="hidden" id="m" name="m" value="service" />
              <input type="hidden" id="token_version_id" name="token_version_id" value="" />
              <input type="hidden" id="enc_data" name="enc_data" />
              <input type="hidden" id="integrity_value" name="integrity_value" />
            </form>
            <form id="findPWform" onSubmit={handleSubmit(findPWSubmit)}>
              <label htmlFor="user_id">아이디 (이메일)</label>
              <input type="email" placeholder="E-mail" className="id" id="user_id" {...register("user_id", { required: true, maxLength: 60 })} />
              {errors.user_id?.type === 'maxLength' && <span>60자 이내로 입력해주세요.</span>}
              {errors.user_id?.type === 'required' && <span>이메일을 입력해주세요.</span>}
            </form>
          </div>
          <Button form='findPWform' type="submit" style={{ margin: '0px auto', backgroundColor: '#7F7F7F' }}>휴대폰 인증하기</Button>

        </>
        :
        <>
          <div className="content">
            비밀번호 변경
            <form id="changePWform" onSubmit={handleSubmit(changePWSubmit)}>
              <div>
                <label>비밀번호 <span>*</span></label>
                <input 
                  type="password" 
                  autoComplete="off" 
                  placeholder="영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요." 
                  {...register("user_pw", { required: true})}
                />
                <span></span>
                {errors.user_pw?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
              </div>
              <div>
                <label>비밀번호 확인 <span>*</span></label>
                <input 
                  type="password" 
                  autoComplete="off" 
                  placeholder="영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요." 
                  {...register("user_pw_check", 
                    { required: true, 
                      validate: (user_pw_check) => { const { user_pw } = getValues(); return user_pw === user_pw_check }
                    }
                  )}
                />
                <span></span>
                {errors.user_pw_check?.type === 'validate' && <span>비밀번호가 일치하지 않습니다.</span>}
                {errors.user_pw_check?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
              </div>
            </form>
          </div>
          <Button form='changePWform' type="submit" style={{ margin: '0px auto', backgroundColor: '#7F7F7F' }}>비밀번호 변경하기</Button>

        </>
      }
      <BlockUI blocking={blocking} />
    </Div>
  )
}

const Div = styled.div`
  .content{
    display: flex;
    flex-direction: column;
    height: 330px;
    justify-content: center;
    font-size: 1.125rem
  }
  span{
    position:relative ;
    color:#EB5757;
  }
  label{
    display: block;
    margin-top: 35px;
    text-align: left;
  }

  input{
    width:100%;
    border-radius: 0;
    border: 1px solid #E4E4E4;
    height: 50px;
    box-sizing: border-box;
    margin-top: 7px;
    padding-left: 18px;
  }
`