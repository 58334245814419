import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./auth";

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation()
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" state={location.pathname}/>;
    // todo: 이전 페이지로 redirect?
  }
  return children;
};