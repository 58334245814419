import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import PageNotFound from "../page/pageNotFound";
import Login from "../page/login";
import { useAuth } from "../auth/auth";
import { useModal } from "../components/modal/modal";

const ErrorHandler = ({ children }) => {
  const { logout } = useAuth()
  const location = useLocation();
  const navigate = useNavigate()
  const { modalShow, modalClose } = useModal()
  
  switch (get(location.state, "errorStatusCode")) {
    case "40101":
      logout()
      return (
        <>
          {children}
          <Login />;
        </>
      )

    case "40103": // Incorrect username or password
      return children

    case "40104": // Duplicate username 단순 아이디 중복 확인
      modalShow({ message: "이미 존재하는 아이디입니다.", actionButton: <div className="button" onClick={() => { modalClose() }}>다시 시도하기</div> })
      return children

    case "40106": // Already Registry User 회원가입 시 아이디 최종 중복 확인.
      modalShow({ message: "이미 가입된 유저입니다.", actionButton: <div className="button" onClick={() => { modalClose(); navigate('start/find') }}>아이디/비밀번호 찾기</div> })
      return children
    
    case "40201": // Not Enough Point
      modalShow({ message: "알 수 없는 에러입니다. 관리자에게 문의해주세요. 에러코드: 40201", actionButton: <div className="button" onClick={() => { modalClose() }}>다시 시도하기</div> })
      return children

    case "40301": // Please Retry
      modalShow({ message: "알 수 없는 에러입니다. 관리자에게 문의해주세요. 에러코드: 40301", actionButton: <div className="button" onClick={() => { modalClose() }}>다시 시도하기</div> })
      return children

    case '40601': // Invalid Data Content (데이터 없는 값 요청시)
      return (
        <>
          {children}
          <PageNotFound withApiError={true} />
        </>
      )

    case "40602": // Already Data Existed
      modalShow({ message: "알 수 없는 에러입니다. 관리자에게 문의해주세요. 에러코드: 40602", actionButton: <div className="button" onClick={() => { modalClose() }}>다시 시도하기</div> })
      return children

    case "40603": // Included Special Char
      modalShow({ message: "알 수 없는 에러입니다. 관리자에게 문의해주세요. 에러코드: 40603", actionButton: <div className="button" onClick={() => { modalClose() }}>다시 시도하기</div> })
      return children

    case "40604": // Account does not exist
      modalShow({ message: "아이디와 비밀번호를 확인해주세요", actionButton: <div className="button" onClick={() => { modalClose() }}>다시 시도하기</div> })
      return children

    default:
      return children;
  }
};

export default ErrorHandler;
