import styled from 'styled-components';
import { useState } from 'react';
import Button from '../button';
import { useModal } from '../../components/modal/modal';
import { GMTtoKSTconverter } from "../../converter";

export default function CancelMatching({ data, callback }) { // data -> matchingData

  const { modalShow, modalClose } = useModal()

  const labelTexts = ['연락 두절', '개인 사정', '천재지변', '블랙리스트', '기타']

  const [userChecked, setUserChecked] = useState(data ? ()=>{
      if(data.matching_cancel_host === 1){ return [true, false] } 
      else if(data.matching_cancel_host === 2){ return [false, true]} 
      else if(data.matching_cancel_host === 3){ return [true, true]} 
      else { return [false, false] }
    }
    : 
    [false, false]
  )

  const [checked, setChecked] = useState([false, false, false, false, false])

  const [taxtValue, setTextValue] = useState(data ? () => {
    const reason = JSON.parse(data.matching_cancel)
    for (let i = 0; i < reason.length; i++) {
      if (labelTexts.indexOf(reason[i]) === -1) {
        return reason[i]
      }
    }
  }
    :
    ''
  )

  const [errors, setErrors] = useState('')

  let cancelData = GMTtoKSTconverter(data?.matching_cancel_datetime)

  let handleUserOnChange = (index) => {
    const updatedCheckedState = userChecked.map((checked, i) => i === index ? !checked : checked)
    setUserChecked(updatedCheckedState);
  }

  let handleOnChange = (index) => {
    const updatedCheckedState = checked.map((checked, i) => i === index ? !checked : checked)
    setChecked(updatedCheckedState);
  }

  let onCancel = () => {
    let host = []
    const hostCheck = userChecked.filter((checked, index) => checked && host.push(index + 1));
    if (hostCheck.length === 0) {
      setErrors('취소 계정을 선택해주세요.')
      return
    }
    const hostToInt = host.reduce((prev, cur) => {
      return prev + cur;
    }, 0) //초기값을 0으로 설정한 것. 선택사항임. 안쓰면 첫번째요소가 들어가게됨.


    let reason = []
    const reasonCheck = checked.filter((checked, index) => checked && reason.push(labelTexts[index]));
    if (reasonCheck.length === 0) {
      setErrors('취소 사유를 선택해주세요.')
      return
    }

    if (checked[checked.length - 1]) { // 기타가 체크된 경우  
      if (taxtValue === "") {
        setErrors('기타 내용을 입력해주세요.')
        return
      }
      reason.pop()
      reason.push(taxtValue)
    }
    setErrors('')
    modalShow({ message: <>취소시 복구 불가능합니다<br />정말로 취소하시겠습니까?</>, actionButton: <div className="button" onClick={() => callback(hostToInt, reason)}>취소하기</div> })
  }

  return (
    <Div>
      {!data ?
        <>
          <div className="hearder">취소 사유</div>
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>취소사유 계정</div>
          <div className="checkbox user">
            {["광고주", "아티스트"].map((value, index) => (
              <p key={value}>
                <input
                  id={value}
                  type="checkbox"
                  value={[1, 2][index]}
                  onChange={() => handleUserOnChange(index)}
                />
                <label htmlFor={value}>{value}</label>
              </p>
            ))}
          </div>
          <div style={{ textAlign: 'left', margin: '20px auto 20px' }}>매칭 취소 사유</div>
          <div className="checkbox">
            {labelTexts.map((value, index) => (
              <p key={value}>
                <input
                  id={value}
                  type="checkbox"
                  onChange={() => handleOnChange(index)}
                />
                <label htmlFor={value}>{value}</label>
              </p>
            ))}
          </div>
          <textarea placeholder="내용을 입력해주세요.(100자 이내)" value={taxtValue} onChange={(e) => { setTextValue(e.target.value) }} />
          {errors && <span className="errorMesage">{errors}</span>}
          <Button style={{ margin: "0px auto 10px" }} onClick={() => { onCancel() }}>취소하기</Button>
        </>
        :
        <>
          <div className="hearder">취소 사유</div>
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>취소사유 계정</div>
          <div className="checkbox user">
            {["광고주", "아티스트"].map((value, index) => (
              <p key={value}>
                <input
                  id={value}
                  type="checkbox"
                  checked={userChecked[index]}
                  readOnly={true}
                />
                <label htmlFor={value}>{value}</label>
              </p>
            ))}
          </div>
          <div style={{ textAlign: 'left', margin: '10px auto' }}>취소 일시</div>
          
          <div>{`${cancelData.getFullYear()}.${cancelData.getMonth() + 1}.${cancelData.getDate()} ${cancelData.getHours()}:${cancelData.getMinutes()}`}</div>

          <div className="checkbox">
            {labelTexts.map((value) => (
              <p key={value}>
                {console.log(value)}
                <input
                  id={value}
                  type="checkbox"
                  checked={
                    value !== '기타' ? JSON.parse(data.matching_cancel).indexOf(value) !== -1 : taxtValue ? true : false
                  }
                  readOnly={true}
                />
                <label htmlFor={value}>{value}</label>
              </p>
            ))}
          </div>
          <textarea value={taxtValue} readOnly={true} />
          <Button style={{ margin: "0px auto 10px" }} onClick={() => { modalClose() }}>닫기</Button>
        </>
      }
    </Div>
  )
}

const Div = styled.div`
  width:340px;
  background-color: white;
  margin: 0 auto 40px;
  padding:10px 20px;
  .hearder{
    color:#000;
    font-size:1.5rem;
    margin:20px auto 20px;
    padding-bottom:20px;
    border-bottom: 1px solid #D9D9D9;
  }
  textarea{
    width: 100%;
    height: 108px;
    border: 1px solid #ECECEC;
    resize: none;
    padding:10px;
    box-sizing: border-box;
    margin-bottom:32px;
  }
  textarea::placeholder{
    font-size: 0.875rem;
    color: #777777;
    font-family: 'AppleSDGothicNeoR00';
  }
  .user{
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #D9D9D9;
  }
  .errorMesage{
    color:#EB5757;
  }
`