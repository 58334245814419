import styled from "styled-components";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { inspirationCategory } from "../../../keywords/keywords";
import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import PageNumber from "../../../components/pageNumber"
import { useModal } from "../../../components/modal/modal";
import BlockUI from "../../../components/blockUI/blockUI";
import { GMTtoKSTconverter } from "../../../converter";
import { useScrollToTop } from "../../../customHook/userScrollToTop";


export default function Inspiration() {

  const navigate = useNavigate()
  const { getAPI } = useAPI()
  const { user } = useAuth()
  const [selectPageno, setSelectPageno] = useState(1)
  const [apiData, setApiData] = useState([])
  const [total_pageno, setTotal_pageno] = useState(1)
  const url = `/admin/inspiration?pageno=${selectPageno}`
  const [blocking, setBlocking] = useState(false)
  const [reload, setReload] = useState()
  const { deleteAPI } = useAPI()
  const { scrollToTop } = useScrollToTop()

  const { deleteModal ,modalClose } = useModal()

  useEffect(() => {
    scrollToTop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    setBlocking(true)
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data.inspiration)
      setTotal_pageno(data.total_pageno)
      setBlocking(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno, user, url, reload])

  const deleteButtonClicked = (inspiration_idx) => {
    deleteModal(inspiration_idx, handleDelete)
  }

  const handleDelete = async (idx) => {
    setBlocking(true)
    let url = `/admin/inspiration?inspiration_idx=${idx}`
    let { result } = await deleteAPI(url, user)
    if (result) {
      modalClose()
      setReload(Math.random())
    }
    setBlocking(false)
  }

  return (
    <Div>
      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>카테고리</th>
            <th>썸네일 이미지</th>
            <th>대제목</th>
            <th>작성자</th>
            <th>등록자 계정</th>
            <th>등록일시</th>
            <th><Button style={{ width: '80%', borderRadius: '4px' }}
              onClick={() => { navigate(`/inspiration/create`) }}
            >콘텐츠 작성</Button></th>
          </tr>
        </thead>
        <tbody>
          {apiData?.map((inspiration) => {
            let registryDate = GMTtoKSTconverter(inspiration.inspiration_datetime)

            return (
              <tr key={inspiration.idx}>
                <td>{inspiration.idx}</td>
                <td>{inspirationCategory[inspiration.inspiration_category-1]}</td>
                <td>
                  <div className="thumbnail">
                    <img src={inspiration.inspiration_thumnail} alt="thumbnail" />
                  </div>
                </td>
                <td>{inspiration.inspiration_main_title}</td>
                <td>{inspiration.inspiration_writer}</td>
                <td>{inspiration.admin_id}</td>
                <td>{`${registryDate.getFullYear()}. ${registryDate.getMonth() + 1}. ${registryDate.getDate()}` }</td>
                <td>
                  <div className="buttons">
                    <div className="edit" onClick={() => { navigate(`/inspiration/update`, { state: { inspiration_idx:inspiration.inspiration_idx } }) }}>수정</div>
                    <div className="delete" onClick={() => { deleteButtonClicked(inspiration.inspiration_idx) }}>삭제</div>
                  </div>
                </td>

              </tr>
            )
          })
          }
        </tbody>
      </table>
      <BlockUI blocking={blocking} />
      {total_pageno >= 2 && <PageNumber total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}

const Div = styled.div`
  width:100%;

  table{
    border-collapse: collapse;
    width: 100%;
    margin:40px 0 80px;

    thead{
      *{
        font-family:'AppleSDGothic-Bold';
        font-size:1.25rem;
      }
      th{
      padding:10px;
    }
    }
    tr{
      height:80px;
      line-height: 80px;
      color: #222222;
      td{
        border-bottom:1px solid #D9D9D9;
        text-align:center;
        height:100px;
      }
      .thumbnail{
        display: flex;
        align-items:center;
        justify-content:center;
        img{
          width:90%;
          max-width:128px;
          aspect-ratio: 1.618 / 1;
          object-fit: cover;
          box-sizing: border-box;
        }
      }
      .category{
        padding-left:24px;
        box-sizing:content;
        border: 1px solid #1F1F22;
        border-radius: 1rem;
      }
    }
    .buttons{
      display: flex;
      justify-content: center;
      >div{
        width:95px;
        height:40px;
        line-height: 40px;
        margin-left:10px;
        cursor:pointer;
      }
      .edit{
        border: 1px solid #7F7F7F;
        box-sizing: border-box;
      }
      .delete{
        background-color: #222;
        color: #FFFFFF;
      }
    }

  }
`