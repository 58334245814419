import MainLogo from "../../images/mainLogo.svg"
import styled from "styled-components";

// login, add wish, my board
export default function ModalContent({modalContent}){
  return(
    <Div>
      <img className="modalLogo" src={MainLogo} alt="Logo"/>
      <div className="message">{modalContent.message}</div>
      {modalContent.actionButton}
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  align-items: center;

  .modalLogo {
    width: 109px;
    height: 31px;
    position: absolute;
    top: 81px;
  }

  .message {
    position: absolute;
    top: 144px;
  }

  .button {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    width: 276px;
    height: 60px;
    line-height: 60px;
    background: #222222;
    color: #FFFFFF;
  }


  .flexContainer {
    // 예 or 아니요 버튼. confirmEmailDuplicationModal 에서 사용
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 15px;

    div {
      width: 46%;
      height: 60px;
      line-height: 60px;
      background: #222222;
      color: #FFFFFF;
    }
  }
`