import ReactSelect from 'react-select'
import styled from 'styled-components';

export default function Select(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      height: '100%',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? 'black' : isFocused ? 'black' : "#7F7F7F",
      backgroundColor: isSelected ? 'rgba(0,0,0,0.2)' : undefined,
      ':active': {
        ...styles[':active'],
        backgroundColor:'rgba(0,0,0,0.2)'
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0px',
      textAlign: 'left',
    }),

    control: (base) => ({
      // ...base,
      ...base,
      backgroundColor: 'white',
      borderRadius: '0px',
      textAlign: 'left',
      height: '100%',
    }),

    valueContainer: (base) => ({
      ...base,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    }
  }

  return (
    <Div>
      <ReactSelect
        {...props}
        styles={customStyles}
        isSearchable={false}
        // defaultValue={props.defaultValue || props.options[0]}
        defaultValue={props.defaultValue}

        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </Div>
  )
}

const Div = styled.div`
  height:100%;
  input{
    height:0px !important; // projectForm input 등에서 설정된 height:50px 때문에 목록글자가 중간에 오지않음
  }

  @media (max-width: 372px) { // sort의 이름순, 등록순 글자 라인이 깨지는 point
    *{font-size:0.813rem}
  }
`