import { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useModal } from '../../components/modal/modal';
import BlockUI from '../../components/blockUI/blockUI';
import Button from '../../components/button';
import styled from 'styled-components';
import SelectFile from '../../images/selectFile.svg';
import Select from '../../components/select/select';
import { occupationList, businessList, occupationIndex } from '../../keywords/keywords';
import { getKeyByValue } from '../../converter';
import useAPI from '../../api/useAPI';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/auth';
import DaumPostcode from 'react-daum-postcode';
import { GMTtoKSTconverter } from "../../converter";



export default function UserForm({ userData, user_idx, readOnly, isScreening, style, reload }) {
  // readOnly -> 조직관리에서만 false. 정보 수정 가능하게. screening, matching에선 true

  const {
    user_registry,
    user_privilege,
    user_phone,
    user_age,
    user_name,
    user_gender,
    user_id,
    user_file,
    user_address,
    user_address_detail,
    company_name,
    user_homepage,
    user_instagram,
    last_datetime,
    job_detail,
    user_block,
  } = userData

  const { user } = useAuth()
  const { register, handleSubmit, formState: { errors }, resetField, setValue, control } = useForm();

  const { confirmModal, reportAndBlockModal, sendMessageModal, emptyModal, modalShow, modalClose } = useModal()

  // eslint-disable-next-line no-unused-vars
  const [_, setBlocking] = useState(false)

  const { putAPI, postAPI, uploadImageAPI } = useAPI()

  const navigate = useNavigate()

  const [address, setAddress] = useState(user_address);

  const [uploadedFile, uploadFile] = useState(()=>{ // user_file이 유효한 JSON type인지 확인.(array or string check)
    try {
      JSON.parse(user_file);
    } catch (e) {
      return user_file;
    }
    return JSON.parse(user_file)
  })

  const jobOptions = []
  for (let i = 0; i < occupationList.length; i++) {
    jobOptions.push({ value: occupationIndex[occupationList[i]], label: occupationList[i] })
  }

  const businessOptions = []
  const businessValueList = Object.values(businessList)
  for (let i = 0; i < businessValueList.length; i++) {
    businessOptions.push({ value: occupationIndex[businessValueList[i]], label: getKeyByValue(businessList, businessValueList[i]) })
  }

  const selectAddress = (data) => {
    setAddress(data.address)
    resetField("user_address")
    modalClose();
  }

  const blockUser = async (user_idx, reason) => {
    let url = `/admin/user/block`
    let data = {
      "user_idx":user_idx,
      "user_block_detail":JSON.stringify(reason)
    }
    let { result } = await putAPI(url, user, data)
    if (result === 'success') {
      !user_block ?
        modalShow({ message: `정지가 완료되었습니다`, actionButton: <div className="button" onClick={() => modalClose()}>닫기</div> })
        :
        modalShow({ message: `정지 해제가 완료되었습니다`, actionButton: <div className="button" onClick={() => modalClose()}>닫기</div> })
    }
    reload()
  }

  const onSubmit = async (data) => {
    setBlocking(true)
    if (isScreening) {

      let message = `${user_name}님을 승인하시겠습니까?`
      let callback = async () => {
        let { result } = await postAPI(`/admin/new/detail?user_idx=${user_idx}`, user)
        if (result === 'success') {
          navigate(`/screening`)
        }
      }
      confirmModal(message, callback)
      setBlocking(false)

    } else {
      // 조직관리 페이지 회원정보 수정 부분
      let message = `회원 정보를 수정하시겠습니까?`
      let callback = async () => {
        data.user_idx = user_idx
        data.job_idx = data.job_detail
        delete data.job_detail

        let uploadResponse = await uploadImageAPI(data.user_file[0] || user_file)
        if (uploadResponse.result === 'success') {
          data.user_file = uploadResponse.data.img_url
        } else {
          setBlocking(false)
          modalShow({ message: `이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요. 에러코드: ${uploadResponse}`, actionButton: <div className="button" onClick={() => modalClose()}>다시 시도하기</div> })
          return
        }
        let { result } = await putAPI('/admin/old', user, data)
        if (result === 'success') {
          navigate(`/organization`)
        }
      }

      confirmModal(message, callback)
      setBlocking(false)
    }

  }
  let registryDate = GMTtoKSTconverter(user_registry)
  let lastDate = GMTtoKSTconverter(last_datetime)


  return (
    <Div style={style}>
      {userData.length !== 0 ?
        <form id='editInfoForm' onSubmit={handleSubmit(onSubmit)}>
          {!isScreening &&
            <div>
              <label>마지막 로그인 일시</label>
              <div className="userData">
                {`${lastDate.getFullYear()}.${lastDate.getMonth() + 1}.${lastDate.getDate()} ${lastDate.getHours()}:${lastDate.getMinutes()}`}
              </div>
            </div>
          }

          <div>
            <label>회원가입 일시</label>
            <div className="userData">{`${registryDate.getFullYear()}. ${registryDate.getMonth() + 1}. ${registryDate.getDate()}`}</div>
          </div>

          {isScreening &&
            <div className="forWeb">
              <label></label>
            </div>
          }
          <div>
            <label>계정 TYPE</label>
            <div className="userData">{user_privilege === 1 ? "아티스트" : "광고주"}</div>
          </div>

          <div>
            <label>회원명</label>
            <div className="userData">{user_name}</div>
          </div>

          <div>
            <label>휴대폰 번호</label>
            <div className="sms">
              <div className="userData">{user_phone}</div>
              {!isScreening &&
                <Button
                  style={{ width: "100px", height: "30px", lineHeight: "30px", borderRadius: "12px", margin: "0 0 0 40px" }}
                  onClick={(e) => { e.preventDefault(); sendMessageModal(userData) }}
                >SMS 보내기</Button>
              }
            </div>
          </div>

          <div>
            <label>생년월일</label>
            <div className="userData">{user_age}</div>
          </div>
          <div>
            <label>성별</label>
            <div className="userData">{user_gender ? '남자' : '여자'}</div>
          </div>
          <div className="forWeb">
            <label></label>
          </div>

          <div>
            <label>이메일</label>
            <div className="sms">
              <div className="userData">{user_id}</div>
              {!readOnly && !user_block && // organigation에서만 보이게
                <Button
                  style={{ width: "80px", height: "30px", lineHeight: "30px", margin: "0 0 0 40px", color: "#222222", backgroundColor: "white", border: "1px solid #222" }}
                  onClick={(e) => { e.preventDefault(); reportAndBlockModal(null, userData.user_idx, blockUser) }}
                >계정 정지</Button>
              }
              {!readOnly && user_block && // organigation에서만 보이게


                <Button
                  style={{ width: "80px", height: "30px", lineHeight: "30px", margin: "0 0 0 40px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    modalShow({ message: `계정정지를 해제하시겠습니까?`, actionButton: <div className="button" onClick={() => blockUser(userData.user_idx)}>해제하기</div> })
                  }}
                >정지 해제</Button>
              }
            </div>
          </div>

          <div className="forWeb">
            <label></label>
          </div>

          {readOnly &&
            <div style={{ gridColumn: "1/3" }}>
              <label>주소</label>
              <div className="userData">{user_address} {user_address_detail}</div>
            </div>
          }
          {!readOnly &&
            <div className="address">
              <label>주소</label>
              <div className="flexWrapper">
                <input type="text" disabled={true} defaultValue={user_address} onChange={setValue("user_address", address || user_address)} {...register("user_address")} />
                <div onClick={() => { emptyModal(<DaumPostcode onComplete={selectAddress} />) }}>검색</div>
              </div>
            </div>
          }
          {!readOnly &&
            <div className="addressDetail">
              <label className="forWeb"></label>
              <input type="text" placeholder="상세주소" defaultValue={user_address_detail} {...register("user_address_detail")} />
            </div>
          }

          {user_privilege === 2 &&
            <>
              <div>
                <label>회사명</label>
                {readOnly ?
                  <div className="userData">{company_name}</div>
                  :
                  <input type="text" defaultValue={company_name} {...register("company_name")} />
                }
              </div>
              <div>
                <label>사업 분야</label>
                <div style={{ height: '50px' }}>
                  {readOnly ?
                    <div className="userData">{getKeyByValue(businessList, job_detail)}</div>
                    :
                    <Controller
                      control={control}
                      name="job_detail"
                      rules={{ required: true }}
                      defaultValue={job_detail}
                      render={() => (
                        <Select
                          placeholder="사업분야를 선택해주세요."
                          onChange={(param) => { resetField('job_detail'); setValue("job_detail", param.value) }}
                          options={businessOptions}
                          defaultValue={businessOptions.find(option => option.label === getKeyByValue(businessList, job_detail))}
                        />
                      )}
                    />
                  }
                </div>
              </div>
            </>
          }

          {user_privilege === 1 &&
            <>
              <div>
                <label>전문분야</label>
                <div style={{ height: '50px' }}>
                  {readOnly ?
                    <div className="userData">{job_detail}</div>
                    :
                    <Controller
                      control={control}
                      name="job_detail"
                      rules={{ required: true }}
                      defaultValue={job_detail}
                      render={() => (
                        <Select
                          placeholder="전문분야를 선택해주세요."
                          onChange={(param) => { resetField('job_detail'); setValue("job_detail", param.value) }}
                          options={jobOptions}
                          defaultValue={jobOptions.find(option => option.label === job_detail)}
                        />
                      )}
                    />
                  }
                </div>
                {errors.job?.type === 'required' && <span className="errorMesage">전문분야를 선택해주세요.</span>}
              </div>
              <div className="forWeb">
                <label></label>
              </div>
            </>
          }

          <div>
            <label>{user_privilege === 1 ? "포트폴리오 홈페이지" : "홈페이지"}</label>
            {readOnly ? <div className="userData">{user_homepage}</div> :
              <input type="text" defaultValue={user_homepage} {...register("user_homepage")} />
            }
          </div>

          <div>
            <label>SNS 주소</label>
            {readOnly ? <div className="userData">{user_instagram}</div> :
              <input type="text" defaultValue={user_instagram} {...register("user_instagram")} />
            }
          </div>
          <div className="preview">
            {readOnly && // 회원가입 승인 & 매칭
              <label>사업자등록증 또는 포트폴리오</label>
            }
            {/* 현재 업로드된 파일이 File object or URL인 경우 존재 */}
            {typeof(uploadedFile) !== 'string' ? 
              uploadedFile.map(file => {
                return (
                  <div key={file}>
                    <img src={file} alt="file icon" />
                    <a href={`${file}`} target="_blank" rel="noreferrer" >다운로드</a>
                  </div>
                )
                // return <img key={file} src={typeof (file) === "object" ? URL.createObjectURL(file) : file} alt="file icon" />
              })
              :
              <>
                <img src={uploadedFile} alt="file icon" />
                <a href={`${user_file}`} target="_blank" rel="noreferrer" >다운로드</a>
              </>
            }
          </div>
          {!readOnly && // 조직관리 
          // TODO: 여러개 업로드로 변경.
          // TODO: 하나 업로드도 배열로 변경.

            <div className="uploadFile">
              <label>사업자등록증 또는 포트폴리오 변경하기</label>
              <img className="selectFileIcon" src={SelectFile} alt="file icon" />
              <input
                type="file" accept="image/*, .zip"
                {...register("user_file", {
                  validate: { lessThan30MB: (files) => !files[0] || files[0]?.size < 30000000, },
                  onChange: (e) => { uploadFile(e.target.files[0]) }
                })}
              />
              {errors.user_file?.type === 'lessThan30MB' && <span className="errorMesage">30Mb이하의 이미지를 업로드해주세요.</span>}
            </div>
          }
          {user_privilege === 1 &&
            <div>
              <label>계좌정보</label>
              <div className="accountData">
                <span>은행명</span>
                <span style={{ color: "#7F7F7F" }}>{userData.user_bank ? userData.user_bank : "없음"}</span>
              </div>
              <div className="accountData">
                <span>예금주명</span>
                <span style={{ color: "#7F7F7F" }}>{userData.user_account ? userData.user_account : "없음"}</span>
              </div>
              <div className="accountData">
                <span>계좌번호</span>
                <span style={{ color: "#7F7F7F" }}>{userData.user_account_name ? userData.user_account_name : "없음"}</span>
              </div>
            </div>
          }

        </form>
        :
        <BlockUI blocking={true} />
      }

      {!readOnly &&
        <Button form='editInfoForm' type="submit">수정하기</Button>
      }
      {isScreening &&
        <Button form='editInfoForm' type="submit">승인하기</Button>
      }
    </Div>
  )
}

const Div = styled.div`
  max-width:780px;
  width: 90%;
  margin: 0 auto 40px;
  
  form{  
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 20px;
    text-align: left;
    margin: 40px auto;

    > div{
      max-width:360px;
    }

    .sms{
      display: flex;
      .userData{
        height:30px;
        line-height:30px;
      }
      >button{
        min-width:90px;
      }
    }

    input{
      border: 1px solid #ECECEC;
      width:100%;
      height:50px;
      box-sizing:border-box;
      padding-left:10px;
      margin-bottom:5px;
    }

    input::placeholder{
      font-size: 0.875em;
      color:#777777;
    }

    .errorMesage{
      color:#EB5757;
    }

    label{
      display: block;
      height:20px;
      margin-bottom: 10px;
    }
  

    .uploadFile{
      position: relative;
      grid-column:1/3;
      max-width:780px;
      .selectFileIcon{
        position: absolute;
        top: 45px;
        left:18px;
      }
      input{
        border-radius: 8px;
        padding-left: 55px;
        line-height: 52px;
      }
    }

    .userData{
      font-size:1.25rem;
    }
    .accountData{
      *{font-size:1.25rem};
      span{
        display: inline-block;
        min-width:80px;
      }
    }

    .preview{
      grid-column:1/3;
      img{
        width:90%;
        max-width:400px;
        max-height:400px;
        object-fit: contain;
        box-sizing: border-box;
      }
      a{
        text-align: center;
        display: block;
        font-size: 1.5rem;
      }
    }

    .address{
      .flexWrapper{
        display: flex;
        justify-content: space-between;
        flex-direction:row;
        input{
          width:71%;
        }
        div{ // 검색 버튼
          margin-bottom: 5px;
          width:25%;
          line-height: 50px;
          text-align: center;
          background-color: #777777;
          font-size: 0.875em;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 720px) {
    max-width:360px;
    width:90%;
    margin:0 auto;
    form{
      display: block;
      > div{
        margin:20px auto;
      }
      .forWeb{
        display:none;
      }
    }
  }
`