import styled from "styled-components";
import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import { GMTtoKSTconverter } from "../../../converter";
import BlockUI from "../../../components/blockUI/blockUI";
import { useScrollToTop } from "../../../customHook/userScrollToTop";
import { useModal } from "../../../components/modal/modal";
import Button from "../../../components/button";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";


export default function ReportDetail() {

  const { getAPI } = useAPI()
  const { user } = useAuth()
  let { report_idx } = useParams();
  const [apiData, setApiData] = useState([])
  const [blocking, setBlocking] = useState(false)
  const url = `/admin/report/detail?user_idx=${report_idx}`
  const { scrollToTop } = useScrollToTop()
  const { reportAndBlockModal } = useModal()


  useEffect(() => {
    scrollToTop()
    setBlocking(true)
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data)
      setBlocking(false)
    }
    fetchAPI()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, url])

  return (
    <Div>
      <div className="header">신고 관리</div>
      <table className="reported">
        <thead>
          <tr>
            <th>신고받은 계정(이메일)</th>
            <th>비지니스 또는 전문분야</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link to={`/organization/${apiData?.user_idx}`}>
                {apiData?.user_id}
              </Link>
            </td>
            <td>{apiData?.job_detail}</td>
          </tr>
        </tbody>
      </table>
      
      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>신고시간</th>
            <th>신고한 계정</th>
            <th>직무/분야</th>
            <th>사유보기</th>
          </tr>
        </thead>
        <tbody>
          {apiData?.report?.map((user) => {
            let reportDate = GMTtoKSTconverter(user.report_datetime)
            return (
              <tr key={user.user_idx}>
                <td>{user.idx}</td>
                <td>{`${reportDate.getFullYear()}.${reportDate.getMonth() + 1}.${reportDate.getDate()} ${reportDate.getHours()}:${reportDate.getMinutes()}`}</td>
                <td>
                  <Link to={`/organization/${user.user_idx}`}>
                    {user.user_id}
                  </Link>
                </td>
                <td>{user.job_detail}</td>
                <td><Button style={{ height:"40px", lineHeight:"40px", width:"80px"}} onClick={()=>{reportAndBlockModal(user.report_detail)}}>보기</Button></td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <BlockUI blocking={blocking} />
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  .header{
    font-size:1.5rem;
    padding:15px;
  }
  .search{
    font-size:1.25rem;
    padding:10px;
    width:100%;
    box-sizing: border-box;
    border-top:1px solid #D9D9D9;
    border-bottom:1px solid #D9D9D9;
    border-left:0;
    border-right:0;
  }
  .seletType{
    display: flex;
    height:50px;
    line-height:50px;
    text-align:center;
    >div{
      padding:0 40px;
      cursor: pointer;
    }
  }

  table{
    border-collapse: collapse;
    width: 98%;
    border-top: 1px solid #D9D9D9;
    margin:0 auto;
    thead{
      *{font-family:'AppleSDGothic-Bold';}
    }
    tr{
      height:60px;
      line-height: 60px;
      color: #222222;
      td{
        padding:0 10px;
        text-align:center;
        img{
          object-fit: cover;
        }
        .artist{
          width:80px;
          height:80px;
        }
        .client{
          max-width:150px;
          max-height:80px;
          width:100%;
          min-width: 120px;
          object-fit: contain;
        }
        .accept{
          text-align:center;
          padding:10px 0;
        }
      }
    }
  }
  .reported{
   width:50%;
   *{text-align: left;}
   margin-left:40px;
   border-top:none;
  }
`