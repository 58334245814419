import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useScrollLock } from "../../customHook/useScrollLock";
import ModalContent from "./modalContent";
import DeleteConfirmModal from "./deleteConfirmModal";
import ConfirmModal from "./confirmModal";
import SendLMS from "./sendLMS";
import DeleteUser from "./deleteUser";
import CancelMatching from "./cancelMatching";
import ReportAndBlockModal from "./reportAndBlockModal";
import styled from "styled-components";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {

  const { lockScroll, unlockScroll } = useScrollLock();

  const [isShow, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  // setShow(false)로 바로 닫아버리면 rerendering돼서 fadeout animation 안먹힘.
  // setVisible(false) 후 일정시간 후 setShow하면 그시간동안 fadeout animation 적용 가능.

  const [content, setContent] = useState('')

  useEffect(() => {
    let timeoutId;
    visible ? setShow(true) : timeoutId = setTimeout(() => setShow(false), 250);

    const keyDownHandler = event => {
      if(!visible){return}
      event.key === 'Escape' && modalClose()
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      if (timeoutId !== undefined) {clearTimeout(timeoutId);}
      document.removeEventListener('keydown', keyDownHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const emptyModal = useCallback((content) => {
    setVisible(true)
    setContent(content)
    lockScroll()
  }, [lockScroll])

  const deleteModal = useCallback((value, callback) => {
    setVisible(true)
    setContent(<DeleteConfirmModal deleteIndex={value} handleDelete={callback} />)
    lockScroll()
  }, [lockScroll])

  const confirmModal = useCallback((message, callback, close) => {
    setVisible(true)
    setContent(<ConfirmModal message={message} callback={callback} close={close} />)
    lockScroll()
  }, [lockScroll])

  const sendMessageModal = useCallback((data) => {
    setVisible(true)
    setContent(<SendLMS data={data} />)
    lockScroll()
  }, [lockScroll])

  const deleteReasonModal = useCallback((data) => {
    setVisible(true)
    setContent(<DeleteUser data={data} />)
    lockScroll()
  }, [lockScroll]);

  const cancelMatchingModal = useCallback((data, callback) => {
    setVisible(true)
    setContent(<CancelMatching data={data} callback={callback}/>)
    lockScroll()
  }, [lockScroll]);

  const reportAndBlockModal = useCallback((data, user_idx, callback) => {
    setVisible(true)
    setContent(<ReportAndBlockModal data={data} user_idx={user_idx} callback={callback}/>)
    lockScroll()
  }, [lockScroll])

  const modalShow = useCallback((content) => {
    setVisible(true)
    setContent(<ModalContent modalContent={content} />)
    lockScroll()
  }, [lockScroll]);

  const modalClose = useCallback(() => {
    setVisible(false)
    unlockScroll()
  }, [unlockScroll]);

  const modalCloseLockScroll = useCallback(() => {
    setVisible(false)
  }, []);

  const value = useMemo(
    () => ({ modalShow, sendMessageModal, deleteModal, emptyModal, confirmModal, deleteReasonModal, cancelMatchingModal, reportAndBlockModal, modalClose, modalCloseLockScroll }),
    /*eslint-disable */
    []
    /*eslint-enable */
  );

  return (
    <ModalContext.Provider value={value}>
      {isShow &&
        <Div visible={visible}
          onClick={(e) => {e.stopPropagation();modalClose()}}
        // close modal when outside of modal is clicked
        >
          <div className="modalContent"
            onClick={e => { e.stopPropagation() }}
          // modal content click시 닫지 않도록.
          >
            {content}
            <svg xmlns="http://www.w3.org/2000/svg"  style={{cursor:'pointer'}} viewBox="0 0 384 512" className="modalClose" onClick={() => {modalClose()}}>
              <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
            </svg>
          </div>
        </Div>
      }
      {children}
    </ModalContext.Provider>

  )

};

export const useModal = () => {
  return useContext(ModalContext);
};


const Div = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  animation: ${props => props.visible ? 'fadeIn' : 'fadeOut'} 0.25s ease-out;

  .modalContent {
    position: relative;
    text-align:center;
    svg{
      cursor: pointer;
    }
  }

  .modalClose {
    fill: white;
    position: absolute;
    top: -24px;
    right: 0;
    width: 16px;
    height: 16px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

`