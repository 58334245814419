export const GMTtoKSTconverter= (time) => {
  let gmtTime =  new Date(time)
  return new Date(gmtTime.getTime())
  // return new Date(gmtTime.getTime()+9 * 60 * 60 * 1000)
}

export const getKeyByValue = (obj, value) => 
  Object.keys(obj).find(key => obj[key] === value);

export const stringToSnakeCase = (word) => {
  return word?.replace(' ', '_').toLowerCase()
}

export const toSnakeCase = (object) => {
  return Object.keys(object)
    .map((key) => {
      return { before: key, after: stringToSnakeCase(key) }
    })
    .reduce((cur, { before, after }) => { return Object.assign(cur, { [after]: object[before] }) }, {});
}
