import styled from "styled-components";
import Select from "../../../components/select/select";
import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import PageNumber from "../../../components/pageNumber"
import { GMTtoKSTconverter } from "../../../converter";
import BlockUI from "../../../components/blockUI/blockUI";
import { useNavigate } from "react-router-dom";
import { useScrollToTop } from "../../../customHook/userScrollToTop";
import Button from "../../../components/button";
import { Link } from "react-router-dom";

export default function Matching(){

  // eslint-disable-next-line no-unused-vars
  const [matchingState, setMatchingState] = useState(0)
  
  const navigate = useNavigate()

  const { getAPI } = useAPI()
  const { user } = useAuth()
  const [selectPageno, setSelectPageno] = useState(1)
  const [apiData, setApiData] = useState([])
  const [total_pageno, setTotal_pageno] = useState(1)
  const [blocking, setBlocking] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')

  const url = `/admin/matching?pageno=${selectPageno}&search=${searchKeyword}&matching_process=${matchingState}`
  const { scrollToTop } = useScrollToTop()

  useEffect(() => {
    scrollToTop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);


  useEffect(() => {
    setBlocking(true)
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data.matching)
      setTotal_pageno(data.total_pageno)
      setBlocking(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno, user, url,searchKeyword])

  const matchingStateOptions = [
    { value: 0, label: '전체 매칭 보기' },
    { value: 1, label: '매칭 신청' },
    { value: 2, label: '매칭 진행' },
    { value: 3, label: '매칭 종료' },
    { value: 4, label: '매칭 취소' }
  ]

  return(
    <Div>
      <div className="header">매칭 관리</div>
      <input className="search" type="text" placeholder="이름 또는 전화번호를 검색해주세요" value={searchKeyword} onChange={(e) => { setSearchKeyword(e.target.value) }} />
      <div className="selectWrapper">
        <div style={{marginRight:'20px'}}>매칭상태:</div>
        <div style={{width: '200px'}}>
          <Select
            onChange={(e) => { setMatchingState(e.value) }}
            options={matchingStateOptions}
            defaultValue={matchingStateOptions[0]}
            />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>매칭 시간</th>
            <th>신청 계정</th>
            <th>인증 이름</th>
            <th>연락처</th>
            <th>요청 받은 계정</th>
            <th>인증 이름</th>
            <th>연락처</th>
            <th>매칭 상태</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {apiData?.map((matching) => {
            
            let registryDate = GMTtoKSTconverter(matching.matching_datetime)

            return (
              <tr key={matching.matching_idx}>
                <td>{matching.idx}</td>
                <td>{`${registryDate.getFullYear()}.${registryDate.getMonth() + 1}.${registryDate.getDate()}` }</td>
                <td>
                  <Link to={`/organization/${matching.client_idx}`}>
                    {matching.client.user_id}
                  </Link>
                </td>
                <td>{matching.client.user_name}</td>
                <td>{matching.client.user_phone}</td>
                <td>
                  <Link to={`/organization/${matching.artists_idx}`}>
                    {matching.artists.user_id}
                  </Link>
                </td>
                <td>{matching.artists.user_name}</td>
                <td>{matching.artists.user_phone}</td>
                <td>{matchingStateOptions.find(option => option.value === matching.matching_process).label}</td>
                <td>
                  <Button onClick={()=>navigate(`/matching/${matching.matching_idx}`)} style={{height: "40px", lineHeight: "40px", margin: "10px auto"}}>보기</Button>
                </td>
                  
              </tr>
            )
          })
          }
        </tbody>
      </table>

      <BlockUI blocking={blocking} />
      {total_pageno >= 2 && <PageNumber total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  .header{
    font-size:1.5rem;
    padding:15px;
  }
  .search{
    font-size:1.25rem;
    padding:10px;
    width:100%;
    box-sizing: border-box;
    border-top:1px solid #D9D9D9;
    border-bottom:1px solid #D9D9D9;
    border-left:0;
    border-right:0;
  }

  .selectWrapper{
    display: flex;
    padding: 15px;
    >div{
      font-size:1.25rem;
      height:40px;
      line-height:40px;
    }
  }

  table{
    border-collapse: collapse;
    width: 100%;
    margin:0 auto;
    padding: 15px;

    thead{
      *{font-family:'AppleSDGothic-Bold';}
    }
    tr{
      height:60px;
      line-height: 60px;
      color: #222222;
      td{
        padding:0 10px;
        text-align:center;
        img{
          object-fit: cover;
        }
        .artist{
          width:80px;
          height:80px;
        }
        .client{
          max-width:150px;
          max-height:80px;
          width:100%;
          min-width: 120px;
          object-fit: contain;
        }
      }
    }

  }

`