import styled from "styled-components";
import { useState, useEffect } from "react";
import Button from "../../../components/button";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import PageNumber from "../../../components/pageNumber"
import { GMTtoKSTconverter } from "../../../converter";
import { useNavigate } from "react-router-dom";
import BlockUI from "../../../components/blockUI/blockUI";
import { useScrollToTop } from "../../../customHook/userScrollToTop";
import { useSearchParams } from 'react-router-dom';

export default function Screeninn() {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  let type = searchParams.get('type')
  const [userType, setUserType] = useState(1) //  아티스트 1, 광고주 2
  const { getAPI } = useAPI()
  const { user } = useAuth()
  const [selectPageno, setSelectPageno] = useState(1)
  const [apiData, setApiData] = useState([])
  const [total_pageno, setTotal_pageno] = useState(1)
  const [blocking, setBlocking] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const url = `/admin/new?pageno=${selectPageno}&u_type=${userType}`
  const { scrollToTop } = useScrollToTop()

  useEffect(() => {
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (type === 'client') {
      setUserType(2)
    } else {
      setUserType(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    setBlocking(true)
    const fetchAPI = async () => {
      if(!searchKeyword){
        let { data } = await getAPI(url, user)
        setApiData(data.user)
        setTotal_pageno(data.total_pageno)
        setBlocking(false)
      } else {
        let newUrl = url+`&search=${searchKeyword}`
        let { data } = await getAPI(newUrl, user)
        setApiData(data.user)
        setTotal_pageno(data.total_pageno)
        setBlocking(false)
      }
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno, user, url, searchKeyword])

  return (
    <Div>
      <div className="header">심사 관리</div>
      <input className="search" type="text" placeholder="이름 또는 전화번호를 검색해주세요" value={searchKeyword} onChange={(e) => { setSearchKeyword(e.target.value) }} />
      <div className="seletType">
        <div style={userType === 2 ? { color: '#7F7F7F' } : { color: 'black' }} onClick={() => {navigate('/screening?type=artist') }}>
          아티스트
        </div>
        <div style={userType === 1 ? { color: '#7F7F7F' } : { color: 'black' }} onClick={() => {navigate('/screening?type=client') }}>
          광고주
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>순번</th>
            <th>계정</th>
            {userType === 1 ?
              <th>직무</th>
              :
              <th>분야</th>
            }
            <th>본인인증(이름)</th>
            <th>연락처</th>
            <th>회원가입일시</th>
            <th>사업자등록증</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {apiData?.map((user) => {
            let registryDate = GMTtoKSTconverter(user.user_registry)
            return (
              <tr key={user.idx} >
                <td>{user.idx}</td>
                <td>{user.user_id}</td>
                <td>{user.job_detail}</td>
                <td>{user.user_name}</td>
                <td>{user.user_phone}</td>
                <td>{`${registryDate.getFullYear()}.${registryDate.getMonth() + 1}.${registryDate.getDate()} ${registryDate.getHours()}:${registryDate.getMinutes()}`}</td>
                <td><a href={`${user.user_file}`} target="_blank" rel="noreferrer" >보기</a></td>
                <td>
                  <div className="accept">
                    <Button style={{ height: "40px", lineHeight: "40px", margin: "10px auto" }} onClick={(e) => {
                      e.preventDefault(); navigate(`/screening/${user.user_idx}`)
                    }}>보기</Button>
                  </div>
                </td>
              </tr>
            )
          })
          }
        </tbody>
      </table>
      <BlockUI blocking={blocking} />
      {total_pageno >= 2 && <PageNumber total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  .header{
    font-size:1.5rem;
    padding:15px;
  }
  .search{
    font-size:1.25rem;
    padding:10px;
    width:100%;
    box-sizing: border-box;
    border-top:1px solid #D9D9D9;
    border-bottom:1px solid #D9D9D9;
    border-left:0;
    border-right:0;
  }
  .seletType{
    display: flex;
    height:50px;
    line-height:50px;
    text-align:center;
    >div{
      width:100px;
      cursor: pointer;
    }
  }

  table{
    border-collapse: collapse;
    box-sizing: border-box;
    width: 98%;

    thead{
      *{font-family:'AppleSDGothic-Bold';}
    }
    tr{
      height:60px;
      line-height: 60px;
      color: #222222;
      th{
        /* border:2px solid #D9D9D9; */
      }
      td{
        /* border:1px solid #D9D9D9; */
        padding:0 10px;
        text-align:center;
      }
    }
    .accept{
      /* width:60px; */
      text-align:center;
      padding:10px 0;
    }

  }
`