import { GMTtoKSTconverter } from "../../../converter"
import styled from "styled-components"

export default function MatchingDescrpition({matchingInfo}) {
  let matchingDate = GMTtoKSTconverter(matchingInfo.matching_start)

  return (
    <Div>
      <div>
        <span>브랜드 명 :</span>
        {matchingInfo.matching_brand}
      </div>
      <div>
        <span>캠페인 카테고리 :</span>
        {matchingInfo.matching_category}
      </div>
      <div>
        <span>캠페인 명 :</span>
        {matchingInfo.matching_name}
      </div>
      <div>
        <span>촬영 예상 일정 :</span>
        {`${matchingDate.getFullYear()}.${matchingDate.getMonth() + 1}.${matchingDate.getDate()}`}
      </div>
      <div>
        <span>촬영 예상 시간 :</span>
        {matchingInfo.matching_hour}시간
      </div>
      <div>
        <span>촬영 예상 장소 :</span>
        {`${matchingInfo.matching_address} ${matchingInfo.matching_address_detail}`}
      </div>
      <div>
        <span>회사 홈페이지 주소 :</span>
        {matchingInfo.matching_homepage}
      </div>
      <div>
        <span>저작물 사용국가 :</span>
        {matchingInfo.matching_scope ? "글로벌" : "대한민국"}
      </div>
      <div>
        <span>저작물 사용기간 :</span>
        {matchingInfo.matching_range}개월
      </div>
      <div>
        <span>저작물 매체 사용범위 :</span>
        {JSON.parse(matchingInfo.matching_type).toString()}
      </div>
      <div>
        <span>스폰서 광고 사용여부 : </span>
        {matchingInfo.matching_sponser ? "예" : "아니요"}
      </div>
      <div>
        <span>영상 촬영 진행 유무 : </span>
        {matchingInfo.matching_filming ? "예" : "아니요"}
      </div>
      <div>
        <span>홈쇼핑 오픈마켓 및 온라인 배너 사용유무 : </span>
        {matchingInfo.matching_banner ? "예" : "아니요"}
      </div>
      <div>
        <span>매장 POP 또는 와이드 사용 여부 </span>
        {matchingInfo.matching_pop ? "예" : "아니요"}
      </div>
      <div>
        <span>인쇄룩북, 고객용 포스트카드 제작 사용 여부 : </span>
        {matchingInfo.matching_card ? "예" : "아니요"}
      </div>
      <div>
        <span>옥외광고 사용 여부 : </span>
        {matchingInfo.matching_outdoor ? "예" : "아니요"}
      </div>
      <div>
        <span>특이사항 : </span>
        {matchingInfo.matching_detail}
      </div>
    </Div>
  )
}

const Div = styled.div`
  width:80%;
  border:1px solid #E4E4E4;
  border-radius: 16px;
  margin:10px 10px 100px;
  padding: 25px;
  text-align:left;
  >div{
    margin-bottom:10px;
    *{font-family:'Jost';}
    span{
      font-family:'AppleSDGothic-Bold';
      margin-right: 5px;
    }
  }

  @media (max-width: 480px) {
    >div{
      span{
        display: block;
        margin-top: 20px;
      }
    }
  }
`