import styled from "styled-components"
import MatchingDescrpition from "./matchingDescrpition"
import { v4 as uuidv4 } from 'uuid';
import { usePopup } from "../../../components/popup/popup";
import { useModal } from "../../../components/modal/modal";
import { useAuth } from "../../../auth/auth";
import useAPI from "../../../api/useAPI";


export default function MatchingProcess({ matchingData, reload, account }) {
  const { PopupShow } = usePopup()
  const { cancelMatchingModal, modalShow, modalClose } = useModal()
  const { putAPI } = useAPI()
  const { user } = useAuth()

  // pager/artist 참고해서 만들기
  const matchingStateOptions = [
    { value: '', label: '전체 매칭 보기' },
    { value: 1, label: '매칭 신청' },
    { value: 2, label: '매칭 진행' },
    { value: 3, label: '매칭 종료' },
    { value: 4, label: '매칭 취소' }
  ]

  const getMatchingStateButton = () => {
    let matchingStateButton = []
    let activeStyle = { backgroundColor: "#222222", color: "#FFFFFF" }
    let unactiveStyle = { backgroundColor: "#F0F0F0", color: "#222222" }
    for (let i = 1; i < matchingStateOptions.length; i++) {
      matchingStateButton.push(
        <span key={uuidv4()} style={matchingData?.matching_process === i ? activeStyle : unactiveStyle}>{matchingStateOptions[i].label}</span>
      )
    }
    return matchingStateButton
  }

  const nextProcess = async (matching_idx) => {
    let url = `/admin/matching/next?matching_idx=${matching_idx}`
    let { result } = await putAPI(url, user)
    result === "success" && modalShow({ message: "매칭진행 상태로 변경되었습니다.", actionButton: <div className="button" onClick={() => { modalClose() }}>닫기</div> })
    reload()
  }

  const completeProcess = async (matching_idx) => {
    let url = `/admin/matching/complete?matching_idx=${matching_idx}`
    let { result } = await putAPI(url, user)
    result === "success" && modalShow({ message: "매칭이 종료되었습니다.", actionButton: <div className="button" onClick={() => { modalClose() }}>닫기</div> })
    reload()
  }

  const cancelProcess = async (host, reason) => {
    console.log(host)
    let url = `/admin/matching/cancel?matching_idx=${matchingData.matching_idx}&matching_cancel_host=${JSON.stringify(host)}&matching_cancel=${JSON.stringify(reason)}`
    let { result } = await putAPI(url, user)
    result === "success" && modalShow({ message: "매칭이 취소되었습니다.", actionButton: <div className="button" onClick={() => { modalClose() }}>닫기</div> })
    reload()
  }


  return (
    <Div>
      <div className="matchingContent">
        <div className="matchingHeader">
          <div className="title"> 매칭 프로세스</div>
          <div className="matchingstates">{getMatchingStateButton()}</div>
        </div>

        <div className="textBalloon">
          <div className="matchingWrapper">
            <MatchingDescrpition key={matchingData.matching_idx} matchingInfo={matchingData} />

          </div>
        </div>
        <div className="buttons">
          {(matchingData.matching_process === 1 || matchingData.matching_process === 2) &&
            <div onClick={() => PopupShow(matchingData, reload, account)}>명세수정</div>
          }
          {matchingData?.matching_process === 1 &&
            <div
              onClick={() => modalShow({ message: "매칭진행 단계로 넘어가시겠습니까?", actionButton: <div className="button" onClick={() => { nextProcess(matchingData.matching_idx); modalClose() }}>넘어가기</div> })}
            >다음단계
            </div>
          }
          {matchingData?.matching_process === 2 &&
            <div
              onClick={() => modalShow({ message: "매칭을 종료하시겠습니까?", actionButton: <div className="button" onClick={() => { completeProcess(matchingData.matching_idx); modalClose() }}>매칭 종료하기</div> })}
            >매칭종료
            </div>
          }
          {(matchingData.matching_process === 1 || matchingData.matching_process === 2) &&
            <div className="cancel" onClick={() => { cancelMatchingModal(null, cancelProcess) }}>매칭취소</div>
          }
          {matchingData.matching_process === 4 &&
            <div onClick={() => { cancelMatchingModal(matchingData, cancelProcess) }}>취소사유 보기</div>
          }
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  width: 100%;
  margin:0 auto;
  >div{
    border-right:1px solid #E4E4E4;
  }
  .selectWrapper{
    height:60px;
  }
  .matchingHeader{
    display: flex;
    justify-content: space-between;
    .title{
      padding-left:15px;
      text-align:left;
      height:60px;
      line-height:60px;
      font-size:1.25rem;
    }
    .matchingstates{
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        width:70px;
        height:30px;
        line-height:30px;
        margin:0 10px;
        text-align: center;
      }
    }
  }
  .matchingList{
    flex:3;
  }
  
  .matchingContent{
    flex:5;
  }
  .matchingWrapper{
    width:95;
    height:600px;
    margin:10px;
    border:1px solid #E4E4E4;
    overflow: scroll;
  }
  .textBalloon{
    
  }
  .buttons{
    display: flex;
    >div{
      display:inline-block;
      text-align: center;
      width: 140px;
      height:40px;
      line-height:40px;
      margin:20px;
      cursor: pointer;
      border: 1px solid #7F7F7F;
    }
    .cancel{
      color:#EE220C;
      border: 1px solid #EE220C;
    }
  }

  @media (max-width: 1200px) {
    width:90%;
    .matchingList{
      .ArtistProfile{
        padding-left:0px !important;
      }
    }
  }
  
  @media (max-width: 900px) {
    .matchingContent{
      .matchingHeader{
        justify-content:center;
        padding: 20px 0;
        .title{
          display: none;
        }
      }
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    .matchingList{
      border-left: 1px solid #E4E4E4;
    }
    .matchingContent{
      border-left: 1px solid #E4E4E4;
      border-bottom: 1px solid #E4E4E4;
      .matchingHeader{
        justify-content:center;
        padding: 20px 0;
        .title{
          display: none;
        }
      }
    }
  }

  
  @media (max-width: 400px) {
    .matchingstates{
      span{
        margin:0 !important;
      }
    }
  }
`