import MainLogo from "../../images/mainLogo.svg"
import styled from "styled-components";
import { useModal } from "./modal";
import { useWindowSize } from '../../customHook/useWindowSize';

export default function DeleteConfirmModal({ handleDelete, deleteIndex}){

  const { modalClose } = useModal()
  const { innerWidth } = useWindowSize()

  return(
    <Div innerWidth={innerWidth}>
      <img className="modalLogo" src={MainLogo} alt="Logo"/>
      <div className="headMessage">영감 콘텐츠 삭제</div>
      <div className="message">한번 삭제하면 복구할 수 있는 방법이 없습니다.<br/>삭제를 진행하시겠습니까?</div>
      <div className="flexContainer">
        <div onClick={()=>{modalClose()}}>취소하기</div>
        <div className="white" onClick={()=>{handleDelete(deleteIndex)}}>삭제하기</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  max-width:575px;
  width: calc(${props => props.innerWidth}px * 0.8);
  height: 336px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  align-items: center;


  .modalLogo {
    width: 109px;
    height: 31px;
    position: absolute;
    top: 65px;
  }

  .headMessage{
    position: absolute;
    top: 111px;
    font-size: 1.5rem;
  }

  .message {
    position: absolute;
    top: 168px;
    font-family: 'Jost';
    font-size: 1.125rem;
    color: #7F7F7F;
  }
  .flexContainer {
    // 예 or 아니요 버튼. confirmEmailDuplicationModal 에서 사용
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 44px;

    div {
      margin:0 5px;
      width: 90px;
      height: 40px;
      line-height: 40px;
      background: #222222;
      color: #FFFFFF;
      cursor: pointer;
    }
    .white{
      border: 1px solid #E4E4E4;
      color: #7F7F7F;
      background: #FFFFFF;
    }
  }
`
