
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Screening from './screening'
import ScreeningDetail from "./screening/screeningDetail";
import Organization from "./organization";
import OrganizationDetail from "./organization/organizationDetail";
import Question from "./question";
import QuestionForm from "./question/questionForm";
import InspirationForm from "./inspiration/inspirationForm";
import Inspiration from "./inspiration";
import Matching from "./matching";
import MatchingDetail from "./matching/matchingDetail";
import Report from "./report";
import ReportDetail from "./report/reportDetail";
import Block from "./block";
import styled from "styled-components";
import { useAuth } from "../../auth/auth";

export default function AdminRoute() {

  const { logout } = useAuth()
  const location = useLocation()
  const navigation = useNavigate()
  const navigationList = [
    {
      url: '/screening',
      title: '회원가입 심사관리',
    },
    {
      url: '/organization',
      title: '조직관리',
    },
    {
      url: '/question',
      title: '1:1 문의 관리',
    },
    {
      url: '/inspiration',
      title: '영감 콘텐츠 관리',
    },
    {
      url: '/matching',
      title: '매칭 관리',
    },
    {
      url: '/block',
      title: '정지 관리',
    },
    {
      url: '/report',
      title: '신고 관리',
    },
  ]

  return (
    <Div>
      <div className="adminAside">
        {navigationList.map((item) => {
          return (
            <div key={item.url} className={`adminAsideItem ${location.pathname.includes(item.url) && 'active'}`} onClick={() => { navigation(item.url) }}>
              <div>
                {item.title}
              </div>
            </div>
          )
        })}
        <div className="logout" onClick={()=>{logout()}}>Log out</div>
      </div>
      <div className="article">
        <Routes>
          <Route index element={<></>}></Route>
          <Route path="/screening" element={<Screening/>}></Route>
          <Route path="/screening/:user_idx" element={<ScreeningDetail/>}></Route>


          <Route path="/organization" element={<Organization/>}></Route>
          <Route path="/organization/:user_idx" element={<OrganizationDetail/>}></Route>

          <Route path="/question" element={<Question/>}></Route>
          <Route path="/question/answer" element={<QuestionForm/>}></Route>

          <Route path="/inspiration" element={<Inspiration/>}></Route>
          <Route path="/inspiration/create" element={<InspirationForm/>}></Route>
          <Route path="/inspiration/update" element={<InspirationForm/>}></Route>
          <Route path="/matching" element={<Matching/>}></Route>
          <Route path="/matching/:matching_idx" element={<MatchingDetail/>}></Route>
          <Route path="/report" element={<Report/>}></Route>
          <Route path="/report/:report_idx" element={<ReportDetail/>}></Route>
          <Route path="/block" element={<Block/>}></Route>
        </Routes>
      </div>

    </Div>

  );
}


const Div = styled.div`
margin-bottom:-100px;
display: flex;
justify-content:space-between;
width:95%;
margin:0 auto;

  .adminAside {
    min-width: 140px;
    border: 1px solid #D9D9D9;
    padding-bottom: 50px;
  }

  .adminAsideItem {
    cursor: pointer;
    height: 80px;
    opacity: 0.5;
    text-align: center;
    div{
      height: 80px;
      line-height: 80px;
      img{
        margin: 0 20px 0 40px;
        vertical-align: middle;
        width:20px;
        height:20px;
      }
    }
  }

  .active {
    background-color: #F0F0F0;
    opacity: 1;
  }

  .article {
    flex: 1;
    margin-left:20px;
    border: 1px solid #D9D9D9;
    position:relative; // PageNumber Component 위치 조정 위해 필요
  }

  .hearder{
    margin: 80px 0;
    font-size: 2rem;
    text-align:center;
  }

  .logout{
    color:#D32F2F;
    font-size:1.25rem;
    margin-top: 50px;
    text-align: center;
    cursor: pointer;
  }









@media (max-width: 1500px) {

}
`