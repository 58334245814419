import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useScrollLock } from "../../customHook/useScrollLock";
import styled from "styled-components";
import MatchingPopup from './matchingPopup'

const PopupContext = createContext();


export const PopupProvider = ({ children }) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  const [isShow, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState('')

  useEffect(() => {
    let timeoutId;
    visible ? setShow(true) : timeoutId = setTimeout(() => setShow(false), 250);
    return () => { if (timeoutId !== undefined) { clearTimeout(timeoutId); } };
  }, [visible]);

  useEffect(() => {
    isShow ? lockScroll() : unlockScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  const PopupShow = useCallback((matchingData, reload, account) => {
    setVisible(true)
    setContent(<MatchingPopup matchingData={matchingData} reload={reload} account={account}/>)
  }, []);

  const PopupClose = useCallback(() => {
    setVisible(false)
  }, []);

  const value = useMemo(
    () => ({ PopupShow, PopupClose }),
    /*eslint-disable */
    []
    /*eslint-enable */
  );

  return (
    <PopupContext.Provider value={value}>
      {isShow &&
        <Div visible={visible}>
          <div
            className="popupContent"
            onClick={e => {
              // popup content click시 닫지 않도록.
              e.stopPropagation();
            }}
          >
            {content}
            <svg xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer'}} viewBox="0 0 384 512" className="popupClose" onClick={() => { PopupClose() }}>
              <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
            </svg>
          </div>
        </Div>
      }
      {children}
    </PopupContext.Provider>
  )
}

export const usePopup = () => {
  return useContext(PopupContext);
};

const Div = styled.div`

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  animation: ${props => props.visible ? 'fadeIn' : 'fadeOut'} 0.25s ease-out;

  .popupContent {
    position: relative;
  }

  .popupClose {
    fill: white;
    position: absolute;
    top: -24px;
    right: 0;
    width: 16px;
    height: 16px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

`