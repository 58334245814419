import styled from "styled-components";
import { useForm } from "react-hook-form";
import Button from "../../../components/button";
import { questionCategory } from "../../../keywords/keywords";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from '../../../auth/auth';
import useAPI from '../../../api/useAPI';
import { GMTtoKSTconverter } from "../../../converter";
import { useModal } from "../../../components/modal/modal";
import DraftEditor from "../inspiration/draftEditor";

export default function QuestionForm() {

  const { handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate()
  const location = useLocation()
  const { postAPI } = useAPI()
  const { user } = useAuth()
  const { confirmModal } = useModal()
  const [board, setBoard] = useState('')

  const {question_idx, question_datetime, admin_datetime, question_category, user_id, user_name, question_title, question_file, question_type, admin_board} = location.state.question

  let askTime = GMTtoKSTconverter(question_datetime)
  let answerTime = admin_datetime ? GMTtoKSTconverter(admin_datetime) : ''


  const questionoptions = [{ value: '', label: '' }]
  for (let i = 0; i < questionCategory.length; i++) {
    questionoptions.push({ value: i + 1, label: questionCategory[i] })
    // <Select/> 에서 defaultValue find시 value가 0이면 인식 못하는듯..
  }

  useEffect(() => {
    if (!location.state) {
      navigate(-1)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    let message = <>작성된 답변은 수정할 수 없습니다. <br/>답변을 작성하시겠습니까?</>
    let callback = async () => {
      data.question_idx = question_idx
      data.admin_board = board

      let { result } = await postAPI('/admin/question', user, data)
      if (result === 'success') {
        navigate(`/question`)
      }
    }
    confirmModal(message, callback)
  }

  return (
    <Div>
      <div className="hearder">Question</div>
      <form className="questionForm" id='questionForm' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>문의유형</label>
          <div>
            {questionCategory[question_category - 1]}
          </div>
        </div>
        <div>
          <label>아이디</label>
          <div>
            {user_id}
          </div>
        </div>
        <div>
          <label>이름</label>
          <div>
            {user_name}
          </div>
        </div>
        <div>
          <label>제목</label>
          <div>
            {question_title}
          </div>
        </div>
        <div>
          <label>등록 날짜</label>
          <div>
            {`${askTime.getFullYear()}.${askTime.getMonth() + 1}.${askTime.getDate()} ${askTime.getHours()}:${askTime.getMinutes()}`}
          </div>
        </div>
        <div>
          <div>
            <label>첨부이미지</label>
          </div>
          {question_file ?
            <div className="preview">
              <img src={question_file} alt="file icon" />
              <a href={`${question_file}`} target="_blank" rel="noreferrer" >다운로드</a>
            </div>
            :
            <div>없음</div>
          }
        </div>

        {question_type ?
          <>
            <div>
              <label>답변 시간</label>
              <div>
                {`${answerTime.getFullYear()}.${answerTime.getMonth() + 1}.${answerTime.getDate()} ${answerTime.getHours()}:${answerTime.getMinutes()}`}
              </div>
            </div>
            <div>
              <label>작성된 답변</label>
              <div>
                {admin_board}
              </div>
            </div>
          </>
          :
          <>
            <div>
              <label>답변 작성</label>
            </div>
            <div>
              <DraftEditor setBoard={setBoard} />
            </div>
            {errors.admin_board?.type === 'required' && <div><span className="errorMesage">답변을 입력해주세요.</span></div>}
          </>
        }
      </form>
      {!question_type ?
        <Button style={{ marginTop: '40px' }} form='questionForm' type="submit">답변하기</Button>
        :
        <Button style={{ marginTop: '40px' }} onClick={() => { navigate(`/question`) }}>뒤로가기</Button>
      }
    </Div>
  )
}

const Div = styled.div`
 width:90%;
 margin:40px auto;

 form>div{
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label{
    width: 150px;
  }

  input{
    border: 1px solid #ECECEC;
    height:50px;
    box-sizing:border-box;
    padding-left:10px;
    margin-bottom:5px;
    width:400px;
  }

  label{
    display: block;
    font-size:1.25rem;
    height: 60px;
    line-height:60px;
  }

  .errorMesage{
    color:#EB5757; // 경고 메세지
  }

  textarea{
    height:280px;
    border: 1px solid #E4E4E4;
    width: 100%;
    box-sizing: border-box;
    padding:30px 20px;
    resize: none;
  }
  .preview{
    img{
      width:90%;
      max-width:400px;
      max-height:400px;
      object-fit: contain;
      box-sizing: border-box;
    }
    a{
      text-align: center;
      display: block;
      font-size: 1.5rem;
    }
  }
 }
`