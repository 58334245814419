import { useEffect, useState } from 'react';
export const useWindowSize = () => { 

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // https://bobbyhadz.com/blog/react-get-window-width-height

  function getWindowSize() {
    const {innerWidth, innerHeight }  = window;
    return {innerWidth, innerHeight};
  }
  return windowSize;  
}

