import styled from 'styled-components';
import { useState } from 'react';
import Button from '../button';
import { useModal } from '../../components/modal/modal';

export default function DeleteUser({data}) {
  const labelTexts = ['서비스가 별로여서', ' 자주 사용하지 않아서', '내 커리어에 도움이 되지 않아서', '다른 서비스가 편해서', '기타']
  const reason = JSON.parse(data)
  const [taxtValue] = useState(()=>{
    for (let i = 0; i < reason.length; i++) {
      if(labelTexts.indexOf(reason[i]) === -1){
        return reason[i]
      }
    }
  })
  const { modalClose } = useModal()

  return (
    <Div>

        <div className="hearder">탈퇴 사유</div>
        <div className="checkbox">
          {labelTexts.map((value) => (
            <p key={value}>
              <input
                id={value}
                type="checkbox"
                checked={
                  value !== '기타' ? JSON.parse(data).indexOf(value) !== -1 : taxtValue ? true : false
                } 
                readOnly={true}
              />
              <label htmlFor={value}>{value}</label>
            </p>
          ))}
        </div>
        <textarea value={taxtValue} readOnly={true}/>
        <Button style={{ margin: "0px auto 10px" }} onClick={() => { modalClose() }}>닫기</Button>
    </Div>
  )
}

const Div = styled.div`
  width:380px;
  background-color: white;
  margin: 0 auto 40px;
  padding:10px 20px;
  .hearder{
    color:#000;
    font-size:1.5rem;
    margin:20px auto 40px;
  }
  textarea{
    width: 100%;
    height: 108px;
    border: 1px solid #ECECEC;
    resize: none;
    padding:10px;
    box-sizing: border-box;
    margin-bottom:32px;
  }
  textarea::placeholder{
    font-size: 0.875rem;
    color: #777777;
    font-family: 'AppleSDGothicNeoR00';
  }
`