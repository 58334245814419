import styled from "styled-components";
import DraftEditor from "./draftEditor";
import { useForm, Controller } from "react-hook-form";
import SelectFile from '../../../images/selectFile.svg'
import { useState, useEffect } from "react";
import Select from "../../../components/select/select";
import Button from "../../../components/button";
import { inspirationCategory } from "../../../keywords/keywords";
import { useNavigate, useLocation } from "react-router-dom";
import BlockUI from "../../../components/blockUI/blockUI";
import { useModal } from "../../../components/modal/modal";
import useAPI from '../../../api/useAPI';
import { useAuth } from '../../../auth/auth';


export default function InspirationForm() {

  const { register, handleSubmit, formState: { errors }, control, setValue, resetField } = useForm();
  const { confirmModal, modalShow, modalClose } = useModal()
  const { getAPI, postAPI, putAPI, uploadImageAPI } = useAPI()
  const { user } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const [blocking, setBlocking] = useState(false)

  const [apiData, setApiData] = useState([])

  const isUpdate = location.pathname === '/inspiration/update'

  const [thumbnail, setThumbnail] = useState(undefined)

  const [board, setBoard] = useState('')

  const inspirationOption = []

  let inspiration_idx = location.state?.inspiration_idx

  for (let i = 0; i < inspirationCategory.length; i++) {
    inspirationOption.push({ value: i + 1, label: inspirationCategory[i] })
    // <Select/> 에서 defaultValue find시 value가 0이면 인식 못하는듯..
  }

  useEffect(() => {
    if (isUpdate) {
      if (!inspiration_idx) {
        navigate(-1)
        return
      }
      const fetchAPI = async () => {
        let url = `/admin/inspiration/detail?inspiration_idx=${inspiration_idx}`
        let { data } = await getAPI(url, user)
        setApiData(data)
        setThumbnail(data.inspiration_thumnail)
        setBoard(data.inspiration_board)
      }
      fetchAPI()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleFileChange = (e) => {
    setThumbnail(e.target.files[0])
  }

  const onSubmit = async (data) => {
    let message = "작성된 내용으로 업로드 하시겠습니까?"
    let callback = async () => {

      if (inspiration_idx) { data.inspiration_idx = inspiration_idx }
      data.inspiration_board = board
  
      let uploadResponse = await uploadImageAPI(data.inspiration_thumnail[0] || thumbnail)
      setBlocking(true)
      if (uploadResponse.result === 'success') {
        data.inspiration_thumnail = uploadResponse.data.img_url
      } else {
        setBlocking(false)
        modalShow({ message: `이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요. 에러코드: ${uploadResponse}`, actionButton: <div className="button" onClick={() => modalClose()}>다시 시도하기</div> })
        return
      }
  
      let result;
      location.pathname === '/inspiration/create' ? { result } = await postAPI('/admin/inspiration', user, data) : { result } = await putAPI('/admin/inspiration', user, data)
      if (result === 'success') {
        navigate(`/inspiration`)
      } else
      setBlocking(false)
  
    }

    confirmModal(message, callback)

  }

  return (
    <Div>
      <div className="hearder">INSPIRATION</div>
      {!isUpdate || apiData.length !== 0 ?
        <form className="inspirationForm" id='inspirationForm' onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>카테고리 구분</label>
            <div style={{ height: '50px', width: '400px' }}>
              <Controller
                control={control}
                name="inspiration_category"
                rules={{ required: true }}
                valueAsNumber={true}
                defaultValue={apiData?.inspiration_category}
                render={() => (
                  <Select
                    onChange={(param) => {resetField('inspiration_category'); setValue("inspiration_category", param.value);}}
                    placeholder="카테고리를 선택해주세요"
                    options={inspirationOption}
                    defaultValue={inspirationOption.find(option => option.value === apiData?.inspiration_category)}
                  />
                )}
              />
            </div>
            {errors.inspiration_category?.type === 'required' && <span className="errorMesage">카테고리를 선택해주세요.</span>}
          </div>

          <div>
            <label>대제목</label>
            <input type="text" defaultValue={apiData?.inspiration_main_title} {...register("inspiration_main_title", { required: true })} />

            {errors.inspiration_main_title?.type === 'required' && <span className="errorMesage">대제목을 입력해주세요.</span>}
          </div>

          <div>
            <label>소제목</label>
            <input type="text" defaultValue={apiData?.inspiration_sub_title} {...register("inspiration_sub_title", { required: true })} />

            {errors.inspiration_sub_title?.type === 'required' && <span className="errorMesage">소제목을 입력해주세요.</span>}
          </div>

          <div>
            <label>작성자</label>
            <input type="text" defaultValue={apiData?.inspiration_writer} {...register("inspiration_writer", { required: true })} />
            {errors.inspiration_writer?.type === 'required' && <span className="errorMesage">작성자를 입력해주세요.</span>}
          </div>

          <div>
            <label>썸네일 이미지</label>
            <div className='uploadFile'>
              <img className="selectFileIcon" src={SelectFile} alt="file icon" />
              <input
                type="file" accept="image/*, .zip"
                {...register("inspiration_thumnail", {
                  validate: {
                    lessThan30MB: (files) => !files[0] || files[0]?.size < 30000000,
                    atLeastOne: files => (files[0] || thumbnail) ? true : false
                  },
                  onChange: (e) => {
                    handleFileChange(e)
                  }
                })} />
            </div>
            {errors.inspiration_thumnail?.type === 'atLeastOne' && <span className="errorMesage">썸네일 이미지를 업로드해주세요.</span>}
            {errors.inspiration_thumnail?.type === 'lessThan30MB' && <span className="errorMesage">30Mb이하의 이미지를 업로드해주세요.</span>}

          </div>

          <div className="previewThumbnail">
            {thumbnail && <img src={typeof (thumbnail) === "object" ? URL.createObjectURL(thumbnail) : thumbnail} alt="file icon" />}
          </div>
          <DraftEditor board={apiData?.inspiration_board} setBoard={setBoard} />
          <Button style={{ marginTop: '40px' }} form='inspirationForm' type="submit">업로드</Button>
        </form>
        :
        <BlockUI blocking={true} />
      }
      <BlockUI blocking={blocking} />
    </Div>
  )
}

const Div = styled.div`
 width:90%;
 margin:40px auto;

 form>div{
  display: flex;
  align-items: center;
  min-width: 940px;

  label{
    width: 150px;
  }

  input{
    border: 1px solid #ECECEC;
    height:50px;
    box-sizing:border-box;
    padding-left:10px;
    margin-bottom:5px;
    width:400px;
  }

  input[type="file"]{
    padding-left:55px; // icon 자리 확보
    line-height: 52px;
    font-size:0.813rem;
    color:#333333;
  }

  /* span{
    color:#EB5757; // 경고 메세지
  } */

  label{
    display: block;
    margin-bottom: 10px;
    font-size:1.25rem;
    height: 60px;
    line-height:60px;
  }

  .uploadFile{
    position: relative;
    .selectFileIcon{
      position: absolute;
      top:16px;
      left:18px;
    }
    input{
      border-radius: 8px;
    }
  }
  .errorMesage{
    color:#EB5757; // 경고 메세지
    padding-left:20px;
  }
  &.previewThumbnail{
    img{
      width:90%;
      max-width:400px;
      aspect-ratio: 1.618 / 1;
      object-fit: cover;
      box-sizing: border-box;
    }
  }


 }
`