import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useAPI from '../../../api/useAPI';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

export default function DraftEditor({ board, setBoard }) {

  const [editorState, setEditorState] = useState()
  const { uploadImageAPI } = useAPI()

  useEffect(() => {
    if (board) {
      const contentBlock = htmlToDraft(board);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [board])


  const addPastedImgToServer = async (file) => {
    const uploadResponse = await uploadImageAPI(file)
    if (uploadResponse.result === 'success') {
      return new Promise(
        (resolve, reject) => {
          resolve({ data: { link: uploadResponse.data.img_url } });
        }
      );
    }
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setBoard(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  };

  return (
    <Div>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper"
        editorClassName="editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            previewImage: true,
            uploadCallback: (file) => addPastedImgToServer(file),
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
          },
        }}
      />
    </Div>
  )
}


const Div = styled.div`
  margin-top:40px;
  min-height:300px;


  .wrapper{
    border: 1px solid #D9D9D9;
  }
  .rdw-editor-toolbar{
    width: 840px;
    box-sizing: border-box;
  }
  .rdw-center-aligned-block * {
    text-align: center !important;
  }
  .editor{
    min-height:300px;
    padding:20px;
    width: 800px;
    box-sizing: content-box;
    img{
      max-width: 800px;
    }
    *{
      font-size:inherit;
      font-weight:inherit;
    }
  }
`