import { useState } from "react"
import styled from "styled-components"
import FindID from "./findID"
import FindPW from "./findPW"

export default function Find() {
  const [findPassword, setFindPassword] = useState(false)

  return (
    <Div>
      <div className="title">회원정보 찾기</div>
      <div className="route">
        <div style={findPassword ? {borderBottom: "1px solid #E4E4E4"} : {borderBottom: "2px solid #222"}} onClick={() => { setFindPassword(false) }}>아이디 찾기</div>
        <div style={!findPassword ? {borderBottom: "1px solid #E4E4E4"} : {borderBottom: "2px solid #222"}} onClick={() => { setFindPassword(true) }}>비밀번호 찾기</div>
      </div>
      <div>
        {!findPassword ?
          <FindID/>:<FindPW/>
        }
      </div>


    </Div>
  )
}

const Div = styled.div`
  max-width: 360px;
  width: 90%;
  margin: 0 auto;
  text-align:center;
 .title{
  font-size:1.5rem;
  margin: 20px auto;
  }
  .route{
    display: flex ;
    justify-content:space-around ;
    div{
      width:100%;
      padding:10px 0;
    }
  }
`