import { ProtectedRoute } from './auth/protectedRoute';
import { AuthProvider } from './auth/auth';
import { ModalProvider } from './components/modal/modal';
import { PopupProvider } from './components/popup/popup';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { ScrollToTopProvider } from './customHook/userScrollToTop';
import ErrorHandler from './errorHandler';
import Admin from './page/admin';
import Login from './page/login';
import Find from './page/find';

const App = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <PopupProvider>
            <ErrorHandler>
              <ScrollToTopProvider>
                <Routes>
                  <Route path="/*" element={<ProtectedRoute><Admin /></ProtectedRoute>}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/find" element={<Find />}></Route>
                </Routes>
              </ScrollToTopProvider>
            </ErrorHandler>
          </PopupProvider>
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App