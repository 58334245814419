import { useState } from "react";


// const [user, setUser] = useLocalStorage("user", null);
//     setUser(newUser, shouldStoreInLocalStorage);


export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue, storeInlocalStorage) => {
    // console.log("local에 저장?? ",storeInlocalStorage)
    if(storeInlocalStorage){
      try {
        // console.log('keyName: ', keyName)
        // console.log('Local storage 변화 전: ',window.localStorage.getItem(keyName))
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
      } catch (err) {}
      // console.log('Local storage 변화 후: ',window.localStorage.getItem(keyName))
      setStoredValue(newValue);
    } else {
      setStoredValue(newValue);
    }
  };
  return [storedValue, setValue];
};

// for auth