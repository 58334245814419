import { useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import DaumPostcode from 'react-daum-postcode';
import Radio from "../radio/radio";
import { useModal } from '../modal/modal';
import { useAuth } from "../../auth/auth";
import { usePopup } from "./popup";
import Button from "../button";
import Select from "../select/select";
import { archiveCategory } from '../../keywords/keywords';
import { stringToSnakeCase } from '../../converter';
import DatePicker from "react-multi-date-picker";
import calenderIcon from '../../images/calenderIcon.svg';
import { useWindowSize } from "../../customHook/useWindowSize";
import useAPI from "../../api/useAPI";

// matching_type -> checkbox value 1개일때 str으로 저장됨. 2개일땐 array.

export default function MatchingPopUp({ matchingData, reload, account }) {
  const { register, handleSubmit, formState: { errors }, control, getValues, setValue, resetField } = useForm();
  const { modalShow, modalClose, confirmModal, emptyModal, modalCloseLockScroll } = useModal()
  const { putAPI } = useAPI()

    const [matching_category, setMatching_category] = useState(matchingData.matching_category)
    const [matching_name, setMatching_name] = useState(matchingData.matching_name)
    const [matching_start, setMatching_start] = useState(matchingData.matching_start+`.000Z`)
    const [matching_hour, setMatching_hour] = useState(matchingData.matching_hour)
    const [matching_address, setMatching_address] = useState(matchingData.matching_address);
    const [matching_address_detail, setMatching_address_detail] = useState(matchingData.matching_address_detail)
    const [matching_homepage, setMatching_homepage] = useState(matchingData.matching_homepage)
    const [matching_scope, setMatching_scope] = useState(matchingData.matching_scope ? "글로벌" : "대한민국")
    const [matching_range, setMatching_range] = useState(matchingData.matching_range)
    const [matching_type, setMatching_type] = useState(JSON.parse(matchingData.matching_type))
    // matchingData.matching_type : ["자사몰"]
    // JSON.parse(matchingData.matching_type) : ['자사몰']

    const [matching_sponser, setMatching_sponser] = useState(matchingData.matching_sponser)
    const [matching_filming, setMatching_filming] = useState(matchingData.matching_filming) 
    const [matching_banner, setMatching_banner] = useState(matchingData.matching_banner)
    const [matching_pop, setMatching_pop] = useState(matchingData.matching_pop)
    const [matching_card, setMatching_card] = useState(matchingData.matching_card)
    const [matching_outdoor, setMatching_outdoor] = useState(matchingData.matching_outdoor)
    const [matching_detail, setMatching_detail] = useState(matchingData.matching_detail)

  const { user } = useAuth()
  const { PopupClose } = usePopup()
  // const [checked, setChecked] = useState([false, false, false])
  // const navigate = useNavigate()
  const { innerWidth } = useWindowSize()
  
  const archiveCategoryOptions = []
  const archiveKeyList = Object.keys(archiveCategory)

  for (let i = 0; i < archiveKeyList.length; i++) {
    archiveCategoryOptions.push({ value: stringToSnakeCase(archiveKeyList[i]), label: archiveKeyList[i] })
  }

  const selectAddress = (data) => {
    setMatching_address(data.address)
    resetField("matching_address")
    modalCloseLockScroll();
  }


  const onSubmit = async (submitData) => {
    let message = "매칭 명세를 수정하시겠습니까?"
    submitData.matching_scope === "글로벌" ? submitData.matching_scope = true : submitData.matching_scope = false
    // required value false 불가능해 label로 사용
    submitData.matching_idx = matchingData.matching_idx
    submitData.matching_process = matchingData.matching_process
    if(typeof submitData.matching_type !== 'object'){
      submitData.matching_type = [submitData.matching_type] // value가 array가 아니라면 Array로 변경
    }
    submitData.matching_type = JSON.stringify(submitData.matching_type)

    // matchingData 의 key 값과 submitData이 겹치는게 있다면? 겹치는게 다르다면 다른값 리턴
    let matchingDataList = Object.keys(matchingData) // matchingData key list
    let changedData = {}
    for (let key of matchingDataList) { 
      if(submitData[key]){ // submitData에 matchingData와 겹치는 Key 있는지 확인. 있다면?
        if(key === "matching_start"){ matchingData[key] = matchingData[key]+`.000Z`} // matching_start 의 .000Z 붙는 문제,
        if(matchingData[key] !== submitData[key]){ // value 같은지 확인. 다르다면?
          if(key === "matching_start"){}
          changedData[key] = {}
          changedData[key].before = matchingData[key]
          changedData[key].after = submitData[key]
        }
      } 
    }
    console.log(changedData, '변화된 데이터')

    // matching_scope  true: 글로벌, false:대한민국
    // 나머지 true시 예, false시 아니요
    // matching_range -> + 개월(사용기간)
    // matching_hour -> + 시간(촬영시간)
    // matching_start -> 2022-12-08 이거 같다면 걍 before after 안만들기
    // matching_type 배열 -> 스트링으로


    let callback = async () => {
      let url = `/admin/matching`
      const { result } = await putAPI(url, user, submitData)
      if(result === 'success'){
        reload()
        PopupClose()
        modalShow({ message: "정상적으로 수정되었습니다", actionButton: <div className="button" onClick={() => modalClose()}>닫기</div> })
      }
    }
    confirmModal(message, callback, modalCloseLockScroll)
  }

  return (
    <Div innerWidth={innerWidth}>
      <div className="matching">
        <div style={{ position: 'relative' }}>
          <div className="header">
            매칭 명세 수정
          </div>
        </div>
        <form id='matchingForm' onSubmit={handleSubmit(onSubmit)}>
          <div className="gridWrapper upper">
            <div>
              <label>캠페인 카테고리 <span>*</span></label>
              <div style={{ height: "50px" }}>
                <Controller
                  control={control}
                  name="matching_category"
                  rules={{ required: true }}
                  valueAsNumber={true}
                  defaultValue={matching_category} // onSubmit의 data.project_category 의 default value
                  render={(field) => ( // field 필요한가?
                    <Select
                      {...field}
                      onChange={(param) => { resetField('matching_category');setMatching_category(param.value); setValue("matching_category", param.value) }}
                      options={archiveCategoryOptions}
                      defaultValue={archiveCategoryOptions.find(option => option.value === matching_category)}
                      placeholder="카테고리"
                    />
                  )}
                />
                {errors.matching_category?.type === 'required' && <span style={{ color: "#EB5757" }}>캠페인 카테고리를 선택해주세요.</span>}
              </div>
            </div>

            <div>
              <label>캠페인 명  <span>*</span></label>
              <input type="text" placeholder="캠페인 명을 입력해주세요." value={matching_name}{...register("matching_name", { required: true, onChange: () => { setMatching_name(getValues("matching_name"))}, })} />
              {errors.matching_name?.type === 'required' && <span style={{ color: "#EB5757" }}>캠페인 명을 입력해주세요.</span>}
            </div>

            <div>
              <label>촬영 예상 일자 <span>*</span></label>
              <div className="inputWidhButton">
                <Controller
                  control={control}
                  name="matching_start"
                  rules={{ required: true }}
                  value={getValues("matching_start")}
                  defaultValue={matching_start}
                  render={({ field }) => (
                    <DatePicker
                      id="matching_start"
                      inputClass="datePicker"
                      placeholder="YYYY/MM/DD"
                      format={"YYYY/MM/DD"}
                      value={matching_start}
                      onChange={(date) => { field.onChange(new Date(date).toISOString());setMatching_start(new Date(date).toISOString());}}
                      // https://thewebdev.info/2021/12/05/how-to-use-react-datepicker-with-react-hooks-forms/
                      selected={field.value}
                    />
                  )}
                />
                <div className="buttonNextToInput">
                  <label htmlFor="matching_start">
                    <img src={calenderIcon} alt="calender icon" />
                  </label>
                </div>
              </div>
              {errors.matching_start?.type === 'required' && <div style={{ color: "#EB5757" }}><span>날짜를 선택해주세요.</span></div>}
            </div>

            <div>
              <label>촬영 예상 시간<span>*</span></label>
              <input type="text" placeholder="캠페인 명을 입력해주세요." value={matching_hour} {...register("matching_hour", { required: true, onChange: () => { setMatching_hour(getValues("matching_hour"))}, valueAsNumber: true, validate: (matching_hour) => { return Number.isInteger(matching_hour) } })} />
              {errors.matching_hour?.type === 'required' && <span style={{ color: "#EB5757" }}>촬영 예상 시간을 입력해주세요.</span>}
              {errors.matching_hour?.type === 'validate' && <span style={{ color: "#EB5757" }}>숫자만 입력해주세요.</span>}
            </div>

            <div className="address">
              <label>촬영 장소</label>
              <div className="flexWrapper">
                <input type="text" placeholder="기본주소" disabled={true} value={matching_address} onChange={setValue("matching_address", matching_address)} {...register("matching_address", { required: true, onChange: () => { setMatching_address(getValues("matching_address"))} })} />
                <div onClick={() => {emptyModal(<DaumPostcode onComplete={selectAddress}/>)}}>검색</div>
              </div>
              {errors.matching_address?.type === 'required' && <span style={{ color: "#EB5757" }}>촬영 장소를 입력해주세요.</span>}
            </div>

            <div>
              <label></label>
              <input type="text" placeholder="상세주소" value={matching_address_detail} {...register("matching_address_detail", { onChange: () => { setMatching_address_detail(getValues("matching_address_detail"))} })}  />
            </div>

            <div>
              <label>홈페이지 혹은 SNS 주소 <span>*</span></label>
              <input type="text" placeholder="캠페인 명을 입력해주세요." value={matching_homepage} {...register("matching_homepage", { required: true, onChange: () => { setMatching_homepage(getValues("matching_homepage"))}  })} />
              {errors.matching_homepage?.type === 'required' && <span style={{ color: "#EB5757" }}>홈페이지 혹은 SNS 주소를 입력해주세요.</span>}
            </div>


          </div>
          <div className="gridWrapper lower">
            <div>
              <label>캠페인 사용국가 <span>*</span></label>
              <Controller
                control={control}
                name="matching_scope"
                rules={{ required: true }}
                defaultValue={matching_scope}
                render={() => (
                  <Radio
                    id="matching_scope"
                    labelTexts={["글로벌", "대한민국"]}
                    values={["글로벌", "대한민국"]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_scope(value)}}
                    defaultValue={matching_scope}
                  />
                )}
              />
              {errors.matching_scope?.type === 'required' && <span style={{ color: "#EB5757" }}>캠페인 사용국가를 선택해주세요.</span>}
            </div>

            <div>
              <label>저작물 사용기간 <span>*</span></label>
              <Controller
                control={control}
                name="matching_range"
                rules={{ required: true }}
                valueAsNumber={true}
                defaultValue={matching_range}
                render={() => (
                  <Radio
                    id="matching_range"
                    labelTexts={["3개월", "6개월", "12개월"]}
                    values={[3, 6, 12]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_range(value)}}
                    defaultValue={matching_range}
                  />
                )}
              />
              {errors.matching_range?.type === 'required' && <span style={{ color: "#EB5757" }}>저작물 사용기간을 선택해주세요.</span>}
            </div>
            <div>
              <label>저작물 매체 사용범위<span>*</span></label>
                <div className="checkbox round">
                  {["자사몰", "SNS", "온라인 입점몰"].map((value, index) => (
                  <p key={value}>
                    <input
                      id={value}
                      type="checkbox"
                      value={value}
                      checked={matching_type.indexOf(value) !== -1} 
                      {...register("matching_type",{
                        validate: matching_type => matching_type.length !== 0 ,
                        onChange: () =>{setMatching_type(getValues("matching_type"))}
                      })
                      }
                    />
                    <label htmlFor={value}>{value}</label>
                  </p>
                ))}
                </div>
              {errors.matching_type?.type === 'validate' && <span style={{ color: "#EB5757" }}>매체 사용범위를 선택해주세요.</span>}

            </div>
            <div>
              <label>SNS 스폰서 광고 사용여부 </label>
              <Controller
                control={control}
                name="matching_sponser"
                defaultValue={matching_sponser}
                render={() => (
                  <Radio
                    id="matching_sponser"
                    labelTexts={["예", "아니요"]}
                    values={[true, false]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_sponser(value)}}
                    defaultValue={matching_sponser}
                  />
                )}
              />
            </div>

            <div>
              <label>영상 촬영여부 </label>
              <Controller
                control={control}
                name="matching_filming"
                defaultValue={matching_filming}                  
                render={() => (
                  <Radio
                    id="matching_filming"
                    labelTexts={["예", "아니요"]}
                    values={[true, false]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_filming(value)}}
                    defaultValue={matching_filming}                  
                  />
                )}
              />
            </div>

            <div>
              <label>홈쇼핑 오픈마켓 및 배너 사용여부 </label>
              <Controller
                control={control}
                name="matching_banner"
                defaultValue={matching_banner}                  
                render={() => (
                  <Radio
                    id="matching_banner"
                    labelTexts={["예", "아니요"]}
                    values={[true, false]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_banner(value)}}
                    defaultValue={matching_banner}                  
                  />
                )}
              />
            </div>

            <div>
              <label>매장 POP 또는 와이드 사용여부 </label>
              <Controller
                control={control}
                name="matching_pop"
                defaultValue={matching_pop}                  
                render={() => (
                  <Radio
                    id="matching_pop"
                    labelTexts={["예", "아니요"]}
                    values={[true, false]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_pop(value)}}
                    defaultValue={matching_pop}                  
                  />
                )}
              />
            </div>

            <div>
              <label>인쇄룩북, 고객용 포스트카드 제작 사용 여부 </label>
              <Controller
                control={control}
                name="matching_card"
                defaultValue={matching_card}
                render={() => (
                  <Radio
                    id="matching_card"
                    labelTexts={["예", "아니요"]}
                    values={[true, false]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_card(value)}}
                    defaultValue={matching_card}
                  />
                )}
              />
            </div>

            <div>
              <label>옥외광고 사용 여부</label>
              <Controller
                control={control}
                name="matching_outdoor"
                defaultValue={matching_outdoor}
                render={() => (
                  <Radio
                    id="matching_outdoor"
                    labelTexts={["예", "아니요"]}
                    values={[true, false]}
                    onChange={(id, value)=>{setValue(id, value);setMatching_outdoor(value)}}
                    defaultValue={matching_outdoor}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <label>특이사항</label>
            <input type="text" placeholder="특이사항을 입력해주세요." value={matching_detail} {...register("matching_detail", {onChange: () => { setMatching_detail(getValues("matching_detail"))} })} />
          </div>

          <div className="underline"></div>
          <div style={{margin:"40px auto", fontSize:"1.5rem"}}>아티스트 계좌 정보</div>
          <div className="gridWrapper lower">
          <div>
              <label>은행</label>
              <input type="text" placeholder="은행을 입력해주세요.(선택사항)" defaultValue={account.user_bank} {...register("user_bank")} />
            </div>

            <div>
              <label>계좌번호</label>
              <input type="text" placeholder="계좌번호를 입력해주세요.(선택사항)" defaultValue={account.user_account} {...register("user_account")} />
            </div>

            <div>
              <label>예금주</label>
              <input type="text" placeholder="예금주를 입력해주세요.(선택사항)" defaultValue={account.user_account_name} {...register("user_account_name")} />
            </div>
          </div>

        </form>
        <div className="buttons">
          <button className="goBack" onClick={() => {PopupClose()}}>Cancel</button>
          <Button
            style={{ width: '100px', height: '40px', lineHeight: '40px', marginLeft: '10px', display: 'inline-block', fontFamily: 'Jost' }}
            form='matchingForm' type="submit"
          >수정하기</Button>
        </div>
      </div>
    </Div>

  )
}

const Div = styled.div`

  background-color: white;    
  max-width:900px;
  width: calc(${props => props.innerWidth}px * 0.8);
  height:680px;
  overflow:scroll ;

  /* width:100%; */
  margin: 0 auto;

  .matching{
    margin:0 auto;
    width:90%;
    .header{
      text-align: center;
      margin-top: 40px;
      margin-bottom:40px;
      font-size: 2.5rem;
    }

    .logo{
      padding:32px 0;
      max-width:380px;
      width: 90%;
      margin: 0 auto;
      height:100px;
      img{
        width:95%;
        height:95%;
        object-fit: contain;
      }
    }
    form{
      width: 100%;
      margin-bottom:24px;
      select, input{
        border: 1px solid #ECECEC;
        width:100%;
        height:50px;
        box-sizing:border-box;
        padding-left:10px;
      }
      select{
        font-size: 0.875rem;
        color:#777777;
      }  
      input{
        margin-bottom:5px;
      }

      input::placeholder{
        font-size: 0.875rem;
        color:#777777;
      }
      
      label{
        display: block;
        height:20px;
        margin-bottom: 10px;
      }

      label > span{
        color:#EB5757; // 강조 표시
      }

      >div > span{
        color:#EB5757; // 경고 메세지
      }
      // -> Date picker에서 색 안변하게 하기위해

      .inputWidhButton{
        display: flex;
        justify-content: space-between;
        position: relative;
        .rmdp-container {
          width:71%
        }
        .datePicker{
          max-width:380px;
          width:100%
          /* width: calc(${props => props.innerWidth}px * 0.8*0.9); */
        }
        .project_member{
          width:280px;
        }
        .buttonNextToInput{
          position:relative;
          display: block;
          width: 50px;
          height: 50px;
          background-color: #222222;
          width:25%;
          img{
            position:absolute;
            width:18px;
            height:20px;
            top:calc(50% - 9px);
            left:calc(50% - 10px);
          }
        }
        .project_memberSearch{
          background: #777777;
          color:#FFFFFF;
          font-size: 0.875rem;
          line-height: 50px;
          text-align: center;
          width: 80px;
          height:50px;
        }
        .project_memberSearchWindow{
          position: absolute;
          width:280px;
          top:50px;
          min-height: 80px;
          border: 1px solid #D9D9D9;
          border-top: none;
          box-sizing: border-box;
          background-color: #FFF;
          z-index: 100;
        }
      }
      .address{
        .flexWrapper{
          display: flex;
          justify-content: space-between;
          flex-direction:row;
          input{
            width:71%;
          }
          div{ // 검색 버튼
            margin-bottom: 5px;
            width:25%;
            line-height: 50px;
            text-align: center;
            background-color: #777777;
            font-size: 0.875em;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .underline{
      border-top: 2px solid #222;
      margin-top:40px;
    }
    }
    .gridWrapper{
      display: grid;
      grid-gap: 18px 10px;
    }
    .upper{
      grid-template-columns:1fr 1fr;

      >div{
        max-width:380px;
        width:90%
      }
    }
    .lower{
      grid-template-columns:1fr 1fr 1fr;
      margin-top:24px;
    }
    .buttons{
      grid-column: 1/3;
      text-align: center;
      margin-bottom: 24px;
      .goBack{
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        color: #666666;
        width:100px;
        height: 40px;
        border-radius: 0;
        font-family: 'Jost';
        cursor: pointer;
      }
    }

    .checkbox{
      display:flex;
      *{font-size:0.825rem;}
      label{
        margin-right: 14px;
      }
    }
    
  }

  
  @media (max-width: 1070px) {
    .lower{
      grid-template-columns:1fr 1fr !important;
    }
  }

  @media (max-width: 720px) {
    .upper{
      grid-template-columns:1fr !important;
    }
  }

  @media (max-width: 670px) {
    .checkbox{
      display:inherit !important;
    }
  }

  @media (max-width: 570px) {
    .lower{
      grid-template-columns:1fr !important;

    }
  }
  

`

// const Div = styled.div`

//   width:1100px;
//   background-color: white;    
//   height:680px;
//   overflow:scroll ;
//   .matchingForm{
//     width:900px;
//     margin:0 auto;
//     .logo{
//       padding:32px 0;
//       width:240px;
//       height:100px;
//       img{
//         width:95%;
//         height:95%;
//         object-fit: contain;
//       }
//     }
//     form{
//       margin-bottom:24px;
//       select, input{
//         border: 1px solid #ECECEC;
//         width:100%;
//         height:50px;
//         box-sizing:border-box;
//         padding-left:10px;
//       }
//       select{
//         font-size: 0.875rem;
//         color:#777777;
//       }  
//       input{
//         margin-bottom:5px;
//       }

//       input::placeholder{
//         font-size: 0.875rem;
//         color:#777777;
//       }
      
//       label{
//         display: block;
//         height:20px;
//         margin-bottom: 10px;
//       }

//       label > span{
//         color:#EB5757; // 강조 표시
//       }

//       >div > span{
//         color:#EB5757; // 경고 메세지
//       }
//       // -> Date picker에서 색 안변하게 하기위해

//       .inputWidhButton{
//         display: flex;
//         justify-content: space-between;
//         position: relative;
//         .datePicker{
//           width:320px;
//         }
//         .project_member{
//           width:280px;
//         }
//         .buttonNextToInput{
//           position:relative;
//           display: block;
//           width: 50px;
//           height: 50px;
//           background-color: #222222;
//           img{
//             position:absolute;
//             width:18px;
//             height:20px;
//             top:calc(50% - 9px);
//             left:calc(50% - 10px);
//           }
//         }
//         .project_memberSearch{
//           background: #777777;
//           color:#FFFFFF;
//           font-size: 0.875rem;
//           line-height: 50px;
//           text-align: center;
//           width: 80px;
//           height:50px;
//         }
//         .project_memberSearchWindow{
//           position: absolute;
//           width:280px;
//           top:50px;
//           min-height: 80px;
//           border: 1px solid #D9D9D9;
//           border-top: none;
//           box-sizing: border-box;
//           background-color: #FFF;
//           z-index: 100;
//         }
//       }
//       .address{
//         .flexWrapper{
//           display: flex;
//           justify-content: space-between;
//           flex-direction:row;
//           input{
//             width:71%;
//           }
//           div{ // 검색 버튼
//             margin-bottom: 5px;
//             width:25%;
//             line-height: 50px;
//             text-align: center;
//             background-color: #777777;
//             font-size: 0.875em;
//             color: #FFFFFF;
//             cursor: pointer;
//           }
//         }
//       }
//     }
//     .gridWrapper{
//       display: grid;
//       grid-row-gap: 18px;
//     }
//     .upper{
//       grid-template-columns:1fr 1fr;

//       >div{
//         width:380px;
//       }
//     }
//     .lower{
//       grid-template-columns:1fr 1fr 1fr;
//       margin-top:24px;
//     }
//     .buttons{
//       grid-column: 1/3;
//       text-align: center;
//       margin-bottom: 24px;
//       .goBack{
//         background: #FFFFFF;
//         border: 1px solid #CCCCCC;
//         color: #666666;
//         width:100px;
//         height: 40px;
//         border-radius: 0;
//         font-family: 'Jost';
//         cursor: pointer;
//       }
//     }
    
//   }
// `