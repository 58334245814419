import { useState, useEffect } from "react";
import useAPI from "../../../api/useAPI";
import { useAuth } from "../../../auth/auth";
import { useParams } from 'react-router-dom';
import MatchingProcess from "./matchingProcess";
import styled from "styled-components";
import UserForm from "../userForm"
import maleFace from "../../../images/maleFace.svg"
import Logo from "../../../images/opacityLogo.png"

export default function MatchingDetail() {

  const { getAPI } = useAPI()
  const { user } = useAuth()
  let { matching_idx } = useParams();
  const [matchingData, setMatchingData] = useState({})
  const [clientData, setClientData] = useState({})
  const [artistData, setArtistData] = useState({})
  const [random, setRandom] = useState()
  const [account, setAccount] = useState()

  useEffect(() => {
    const fetchAPI = async () => {
      const url = `/admin/matching/detail?matching_idx=${matching_idx}`
      let { data } = await getAPI(url, user)
      setMatchingData(data)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, random])

  useEffect(() => {
    if (Object.keys(matchingData).length === 0) { return }
    const clientFetchAPI = async () => {
      const url = `/admin/old/detail?user_idx=${matchingData.client_idx}`
      let { data } = await getAPI(url, user)
      setClientData(data)
    }
    clientFetchAPI()

    const artistFetchAPI = async () => {
      const url = `/admin/old/detail?user_idx=${matchingData.artists_idx}`
      let { data } = await getAPI(url, user)
      setArtistData(data)
      setAccount({
        "user_account" : data.user_account,
        "user_account_name" : data.user_account_name,
        "user_bank": data.user_bank
      })
    }
    artistFetchAPI()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingData])

  const reload = () => {
    setRandom(Math.random())
  }

  return (
    <Div>
      {Object.keys(clientData).length !== 0 &&
        <div className="userForm">
          <div className="header">매칭 신청 광고주 정보</div>
          <div className="profile">
            <div>
              <img className="clientImage" src={clientData.user_profile || Logo} alt="client_image" />
            </div>
            <div className="profileDetail">
              <div>{clientData.company_name}</div>
            </div>
          </div>
          <UserForm userData={clientData} readOnly={true} style={{ border: "1px solid #E4E4E4", padding: "20px", boxSizing: "border-box" }} />
        </div>
      }
      {Object.keys(artistData).length !== 0 &&
        <div className="userForm">
          <div className="header">매칭 문의 중인 전문 아티스트 정보</div>
          <div className="profile">
            <div>
              <img className="artistImage" src={artistData.user_profile || maleFace} alt="artist_image" />
            </div>
            <div className="profileDetail">
              <div>{artistData.user_name}</div>
              <div className="occupation">{artistData.job_detail}</div>
            </div>
          </div>

          <UserForm userData={artistData} readOnly={true} style={{ border: "1px solid #E4E4E4", padding: "20px", boxSizing: "border-box" }} />
        </div>
      }
      {Object.keys(matchingData).length !== 0 &&
        <MatchingProcess matchingData={matchingData} reload={reload} account={account}/>
      }
    </Div>
  )
}

const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .userForm{
    .header{
      width: 90%;
      margin: 20px auto;
      font-size:1.25rem;
    }
  }
  .profile{
    width:90%;
    display: flex;
    box-sizing: border-box;
    margin:0px auto;
    text-align: center;
    align-items: center;
    border: 1px solid rgb(228, 228, 228);
    border-bottom:0px;
    .artistImage{
      display: block;
      width: 72px;
      height:72px;
      object-fit: cover;
      border-radius: 50%;
      margin: 10px 0px 10px 10px;
    }
    .clientImage{
      display: block;

      margin: 10px 0px 10px 10px;

      max-width:150px;
      width:100%;
      height:72px;
      object-fit: contain;
    }



    .profileDetail{
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 20px;
      text-align: left;
    }
    .occupation{
      font-family: "Jost";
      color: #7F7F7F;
    }
  }

`