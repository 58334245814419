export const occupationList = ["Photographer", "Filmmaker", "Model", "Hair", "Makeup", "Stylist", "Location"]

export const businessList = {
  "패션": "Fashion",
  "뷰티": "Beauty",
  "유통": "Retail",
  "식/음료": "Food",
  "게임": "Game",
  "전자": "Electronic",
  "교육/출판": "Education",
  "금융": "Finance",
  "잡화": "Merchandise",
  "라이프스타일": "Lifestyle",
}

export const occupationIndex = {
  "Photographer": 1,
  "Filmmaker": 3,
  "Model": 4,
  "Hair": 5,
  "Makeup": 6,
  "Stylist": 7,
  "Location": 8,
  "Food": 2,
  "Merchandise": 9,
  "Beauty": 10,
  "Game": 11,
  "Retail": 12,
  "Education": 13,
  "Finance": 14,
  "Fashion": 15,
  "Electronic": 16,
  "Lifestyle": 17
}

export const archiveCategory = {
  "LOOKBOOK":1,
  "CAMPAIGN":2,
  "E-COM":3,
  "MAGAZINE":4,
  "COMMERCIAL":5,
}

export const questionCategory = ["일반 문의", "계정 관련", "서비스 이용 관련", "사이트 내 에러", "신고하기", "회원탈퇴",]

export const inspirationCategory = ["패션/뷰티/라이프스타일", "영화/음악/서적", "광고/콘텐츠","마케팅/사람", "로케이션/전시/콘서트"]