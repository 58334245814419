import { useState } from 'react';
import BlockUI from '../../components/blockUI/blockUI';
import { useForm } from "react-hook-form";
import { useAuth } from '../../auth/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/button';
import useAPI from '../../api/useAPI';


function Login() {

  const { login } = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [wrongInfo, setWrongInfo] = useState(false)
  const [blocking, setBlocking] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const { postAPI } = useAPI()
  const navigate = useNavigate()

  const location = useLocation()

  const onSubmit = async (submitData) => {
    setBlocking(true)
    setWrongInfo(false)

    const response = await postAPI(`/jwt/login`, false, submitData)

    if(response.result){
      let user = await response.data
      login(user, rememberMe, location.state) // login으로 redirect 되기전의 page 정보
      setWrongInfo(false)
      setBlocking(false)
      return
    }
    if(response.responseCode === '40103'){
      setWrongInfo(true)
      setBlocking(false)
      return
    }
    setBlocking(false)
    return
  }

  let handleOnChange = () => {
    setRememberMe(!rememberMe)
  }

  return (
    <Div>
      <form id="loginForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="hearder">Admin Login</div>
        <label htmlFor="admin_id">아이디</label>

        <input type="text" placeholder="E-mail" className="id" id="admin_id" {...register("admin_id", { required: true, maxLength: 60 })} />
        {errors.admin_id?.type === 'maxLength' && <span>60자 이내로 입력해주세요.</span>}
        {errors.admin_id?.type === 'required' && <span>이메일을 입력해주세요.</span>}


        <label htmlFor="admin_pw">비밀번호</label>
        <input type="password" className="password" autoComplete="off" placeholder="Password" name="admin_pw" {...register("admin_pw", { required: true })} />
        {errors.admin_pw?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
        <div className="checkbox">
          <p key="자동로그인">
            <input
              id="자동로그인"
              type="checkbox"
              onChange={() => handleOnChange()}
            />
            <label htmlFor="자동로그인">자동로그인</label>
          </p>

      </div>
        <Button form='loginForm' type="submit" style={{ margin: "10px auto 10px" }}>Continue</Button>
        {wrongInfo && <div className="wrongInfo">*아이디 혹은 비밀번호가 틀렸습니다.</div>}
        <div className="find" onClick={()=>{navigate('/find')}}><div style={{cursor:'pointer'}}>아이디/비밀번호 찾기</div></div>
      </form>
      <BlockUI blocking={blocking} />
    </Div>
  );
}
export default Login;

const Div = styled.div`

  margin-bottom: 50px;
  margin-top:200px;
  form {
    display: flex;
    flex-direction: column;
    color: #222222;
    width: 380px;
    margin:0 auto;

    .hearder {
      text-align: center;
      font-family: 'Jost';
      font-size: 1.5rem;
      color: #7F7F7F;
    }

    span{
      color:#EB5757;
    }

    label{
      margin-top: 27px;
      text-align: left;
    }

    .id,
    .password {
      border-radius: 0;
      border: 1px solid #E4E4E4;
      height: 50px;
      box-sizing: border-box;
      margin-top: 7px;
      padding-left: 18px;
    }

    .checkboxWrapper {
      margin-top: 5px;
      margin-bottom:20px;
      text-align:left ;
      .rememberMe {
        font-size: 0.875rem;
        color: #7F7F7F;
        vertical-align: text-top;
        margin-left: 5px;
      }
  
    }

    .wrongInfo{
      color:#EE220C;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
    .find{
      text-align: center;
      font-size: 0.875rem;
      color: #7F7F7F;
    }
  }
  @media (max-width: 440px) {
    form{
      width:90%;
    }
  }

`

